@import "../../../node_modules/bootstrap/scss/functions"
@import "../../../node_modules/bootstrap/scss/variables"
@import "color"
$enable-rounded: false
@import "../../../node_modules/bootstrap/scss/bootstrap"
@import 'size'
@import 'theme'
@import 'base'

//@import '_theme'

#goodpin
  background-color: #fff