// media query
@mixin respond($breakpoint)

  @if $breakpoint==desktop-lg
    @media only screen and (max-width: 1279px)
      @content


  @if $breakpoint==desktop
    @media only screen and (max-width: 1024px)
      @content


  @if $breakpoint==tablet
    @media only screen and (max-width: 768px)
      @content


  @if $breakpoint==mobile
    @media only screen and (max-width: 425px)
      @content

@mixin size-same($width, $height: $width)
  width: $width
  height: $height
