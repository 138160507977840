.ng-progress-background
  position: fixed
  display: block
  background-color: rgba(0, 0, 0, 0.9)
  left: 0
  right: 0
  top: 0
  bottom: 0
  text-align: center
  z-index: 200000

  &.blur-off
    background-color: transparent

  &:before
    display: inline-block
    height: 100%
    content: ' '
    vertical-align: middle

  .progress-message-window
    display: inline-block
    text-align: center
    max-width: 600px
    border-radius: 3px
    width: 100%
    background-color: transparent
    padding: 1em
    vertical-align: middle

    i.fa-spinner
      margin-right: 1em

    .bootpay-text
      margin-top: 0.5rem
      font-weight: 500
      color: #fff

.bootpay-bar-loader
  width: 60px
  height: 50px
  border-radius: 100%
  position: relative
  margin: 0 auto

  & > span
    display: inline-block
    width: 5px
    height: 25px
    margin: 0 0.15rem
    background-color: rgba(61, 165, 244, 0.7)

  span:nth-child(1)
    animation: bootpay-grow 1s ease-in-out infinite

  span:nth-child(2)
    animation: bootpay-grow 1s ease-in-out 0.15s infinite

  span:nth-child(3)
    animation: bootpay-grow 1s ease-in-out 0.30s infinite

  span:nth-child(4)
    animation: bootpay-grow 1s ease-in-out 0.45s infinite


.bootpay-loading-spinner
  display: inline-block
  width: 42px
  height: 42px
  vertical-align: middle

  & > .bootpay-circle
    display: inline-block
    width: 42px
    height: 42px
    animation: bootpay-loading-rotate 2s linear infinite
    vertical-align: middle

    & > .bootpay-path
      stroke-dasharray: 90, 150
      stroke-dashoffset: 0
      stroke-width: 2
      stroke: #ffffff
      stroke-linecap: round
      animation: bootpay-loading-dash 1.5s ease-in-out infinite

@keyframes bootpay-grow
  0%, 100%
    -webkit-transform: scaleY(1)
    -ms-transform: scaleY(1)
    -o-transform: scaleY(1)
    transform: scaleY(1)
  50%
    -webkit-transform: scaleY(1.8)
    -ms-transform: scaleY(1.8)
    -o-transform: scaleY(1.8)
    transform: scaleY(1.8)


@keyframes bootpay-loading-rotate
  100%
    transform: rotate(360deg)

@keyframes bootpay-loading-dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
  50%
    stroke-dasharray: 90, 150
    stroke-dashoffset: -40px
  100%
    stroke-dasharray: 90, 150
    stroke-dashoffset: -120px

@-webkit-keyframes bootpay-loading-rotate
  100%
    transform: rotate(360deg)

@-webkit-keyframes bootpay-loading-dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
  50%
    stroke-dasharray: 90, 150
    stroke-dashoffset: -40px
  100%
    stroke-dasharray: 90, 150
    stroke-dashoffset: -120px