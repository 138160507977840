$header-height: 50px
$menu-width: 240px

$hide-menu-width: 1300px
$menu-transition: all 200ms

//m-pagination
$s-button-width: 20px
$s-font-size: 12px

//fonts-size
.fs-1
  font-size: 0.5rem

.fs-2
  font-size: 0.75rem

.fs-2_5
  font-size: 0.875rem !important

.fs-3
  font-size: 1rem

.fs-3_5
  font-size: 1.125rem

.fs-4
  font-size: 1.25rem

.fs-5
  font-size: 1.5rem

.fs-6
  font-size: 1.75rem

.fs-36
  font-size: 36px
  @media (max-width: 576px)
    font-size: 7vw

.fs-29
  font-size: 29px
  @media (max-width: 576px)
    font-size: 7vw


// fixed-width

.w-20
  width: 20%

.w-80
  width: 80%

.lh-60px
  line-height: 60px

.w-40px
  width: 40px

.w-50px
  width: 50px

.w-60px
  width: 60px

.w-100px
  width: 100px

.w-150px
  width: 150px

.w-200px
  width: 200px

.w-300px
  width: 300px

.w-400px
  width: 400px !important

.w-500px
  width: 500px

//max-width
.mw-1000
  max-width: 1000px

.mw-1200
  max-width: 1200px

.mw-1400
  max-width: 1400px

.mt-20
  margin-top: 20px

.mb-20
  margin-bottom: 20px

.mb-30
  margin-bottom: 30px

.pl-1_5
  padding-left: 0.675rem

.pr-1_5
  padding-right: 0.675rem

.ml-4_5
  margin-left: 2rem