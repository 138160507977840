.maintenance-index
  position: fixed
  display: block
  left: 0
  top: 0
  bottom: 0
  right: 0
  text-align: center
  background-color: #EEEEEE
  color: #333
  z-index: 100

  &:before
    display: inline-block
    height: 100%
    content: ' '
    vertical-align: middle

  .maintenance-content
    display: inline-block
    max-width: 600px
    width: 100%
    vertical-align: middle

    .header
      padding: 1em 0

      .maintenance-icon
        position: relative
        width: 110px
        height: 105px
        margin: 0 auto
        text-align: left

      .gear-1
        position: absolute
        top: 10px
        left: 0
        font-size: 72px

      .gear-2
        position: absolute
        right: 25px
        top: 5px
        font-size: 36px

      .gear-3
        position: absolute
        right: 0
        bottom: 10px
        font-size: 48px

    .body
      padding: 1em 0

      p.comment
        font-size: 16px

    hr
      border-top: 1px solid #ccc
      border-bottom: 1px solid #fff

.ion-spin
  animation: spin 2s infinite linear

@-moz-keyframes spin
  0%
    -moz-transform: rotate(0deg)
  100%
    -moz-transform: rotate(359deg)
@-webkit-keyframes spin
  0%
    -webkit-transform: rotate(0deg)
  100%
    -webkit-transform: rotate(359deg)
@-o-keyframes spin
  0%
    -o-transform: rotate(0deg)
  100%
    -o-transform: rotate(359deg)
@-ms-keyframes spin
  0%
    -ms-transform: rotate(0deg)
  100%
    -ms-transform: rotate(359deg)
@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(359deg)