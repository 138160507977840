/* ----------------------------------------------------------------
	mixin style
---------------------------------------------------------------- */
@import "../../../node_modules/bootstrap/scss/variables";

@mixin rounded($ra) {
    -webkit-border-radius: $ra;
    -moz-border-radius: $ra;
    border-radius: $ra;
}
@mixin text-shadow($x, $y, $blur, $color) {
    -webkit-text-shadow: $x $y $blur $color;
    -moz-text-shadow: $x $y $blur $color;
    text-shadow: $x $y $blur $color;
}
@mixin box-shadow($params) {
    -webkit-box-shadow:$params;
    -moz-box-shadow:$params;
    box-shadow:$params;
}
@mixin box-sizing {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
@mixin linear-gradient($from, $to) {
    background-color: $to;
    background-image: -moz-linear-gradient($from, $to); // Firefox
    background-image: -o-linear-gradient($from, $to); // Opera
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to)); // Chrome11+
    background-image: -webkit-linear-gradient($from, $to); // Safari5.1+, Chrome10+
    background-image: -ms-linear-gradient($from, $to); // IE10
    background-image: linear-gradient($from, $to); // W3C
}
@mixin radial-gradient($from, $to) {
    background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
    background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: radial-gradient(center, circle cover, $from 0%, $to 100%);
    background-color: $from;
}

// size
@mixin size($width, $height) {
    width: $width;
    height: $height;
}
@mixin size-same($width, $height: $width) {
    width: $width;
    height: $height;
}

// transition
@mixin transition ($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

// generic transform
@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    -o-transform: $transforms;
    transform: $transforms;
}

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// Skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

// animation keyframe
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

// opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

// placeholder
@mixin placeholder() {
    $selector: '';
    $prefixes: (
            moz: "::-moz",
            webkit: "::-webkit",
            ie: ":-ms"
    );
    @each $prop, $value in $prefixes {
        @if $prop != "moz" {
            $selector: #{$value}-input-placeholder;
        } @else {
            $selector: #{$value}-placeholder;
        }
        @if & {
            &#{$selector} {
                @content;
            }
        } @else {
            #{$selector} {
                @content;
            }
        }
    }
    &::placeholder {
        @content;
    }
}

// clearfix
//@mixin clearfix {
//    &:before, &:after {
//        content: ' ';
//        display: table;
//    }
//    &:after {
//        clear: both;
//    }
//}

// overflow hidden
@mixin ellipsis{
    overflow: hidden;
    min-height: 22px;
    display: block;
    white-space: nowrap;
    word-wrap: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@mixin nth-ani-delay($num_items: 7, $delay_time: 0.2s){
    @for $i from 1 through $num_items {
        &:nth-child(#{$i}) {
            animation-delay: $delay_time * $i;
        }
    }
}

// screen reader
//@mixin sr-only {
//    position: absolute;
//    width: 1px;
//    height: 1px;
//    padding: 0;
//    overflow: hidden;
//    clip: rect(0, 0, 0, 0);
//    white-space: nowrap;
//    clip-path: inset(50%);
//    border: 0;
//}

// :hover & .active
@mixin hover-active {
    &:hover,
    &:active {
        @content;
    }
}

// logo
@mixin logo{
    background:url("../../assets/images/page/logo.svg")no-repeat center;
    span{
        @include sr-only;
    }
}

// arrow
@mixin arrow($width, $height, $border-width, $color, $deg){
    &::after{
        position:relative;
        display:inline-block;
        content:'';
        width:$width;
        height:$height;
        border-width:$border-width $border-width 0 0;
        border-style:solid;
        border-color:$color;
        @include rotate($deg);
        @include transition(all .15s linear);
    }
}

// media query
@mixin respond($breakpoint) {

    @if $breakpoint==desktop-lg {
        @media only screen and (max-width: 1279px) {
            @content;
        }
    }
    @if $breakpoint==desktop {
      @media only screen and (max-width: 1024px) {
            @content;
        }
    }
    @if $breakpoint==tablet {
      @media only screen and (max-width: 768px) {
            @content;
        }
    }
    @if $breakpoint==mobile {
        @media only screen and (max-width: 425px) {
            @content;
        }
    }
}

// media query - custom devices
@mixin rwd-down($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}

@mixin rwd-up($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}