.brand-index
  & > section.header
    & > h3.title
      font-weight: bold
      font-size: 42px
      @media (max-width: 576px)
        font-size: 7vw

    & > .card-wrap
      padding: 1rem 0
      margin: 0 -8px

      @media(max-width: 576px)
        overflow-x: auto

      & > .hot-card
        position: relative
        z-index: 1
        padding: 0
        margin: 0

        & > a
          display: block

          & > img
            position: relative
            z-index: 1
            width: 100%

          & > .logo
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            z-index: 10
            width: 100%

            & > img
              position: absolute
              left: 50%
              top: 50%
              transform: translate(-50%, -50%)
              width: 50%

              &.online
                max-height: 60px

              &.tcash
                max-height: 60px
                width: auto

            & > span.text
              position: absolute
              left: 50%
              top: 50%
              transform: translate(-50%, -50%)
              font-size: 14px
              white-space: nowrap

    & > .search
      margin: 1rem 0
      border-radius: 20px
      padding: 3rem 1rem
      background-color: #FEFAFA
      text-align: center
      @media(max-width: 768px)
        padding: 1.5rem 1rem

      & > .input-control
        position: relative
        display: inline-block
        background-color: #fff
        height: 60px
        line-height: 60px
        border-radius: 30px
        padding: 0 3rem 0 1rem
        border: 0
        overflow: hidden
        max-width: 600px
        width: 100%

        & > input.no-border
          display: block
          height: 60px
          line-height: 60px
          font-size: 18px
          border: 0
          outline: 0
          width: 100%
          cursor: text

        & > a.search-btn
          position: absolute
          right: 1.5rem
          top: 50%
          transform: translate(0, -46%)

          & > i
            font-size: 28px

  & > section.body

    & > .main
      padding: 0
      margin: 0

      & > .left
        padding: 0
        margin: 0
        width: 250px

        & > .left-side
          border: 1px solid #eee
          border-radius: 12px
          padding: 1.5rem
          max-height: 415px
          overflow-x: hidden
          overflow-y: auto
          @media(max-width: 576px)
            max-height: inherit

          @media(max-width: 576px)
            border: 0
            padding: 0.5rem 1rem

          & > h5
            padding: 0
            margin: 0
            font-size: 16px
            @media(max-width: 576px)
              margin-bottom: 1rem

            & > a
              display: block
              position: relative
              font-weight: bold

              & > span.text
                color: #333

              & > i
                position: absolute
                right: 0
                top: 50%
                transform: translate(0, -50%)
                font-size: 18px

          & > ul.left-side-list
            padding: 0
            margin: 1rem 0 0 0
            list-style: none
            @media(max-width: 576px)
              display: flex
              flex-wrap: wrap

            & > li
              padding: 0.1rem 0
              @media(max-width: 576px)
                padding: 0.5rem 0
                flex: 0 0 33%
                max-width: 33%
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis

              & > a
                display: block
                color: #888 !important
                font-size: 15px
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis

              &.active
                & > a
                  color: #333 !important
                  font-weight: bold

          & > .brand-list
            margin: 16px -16px 0 -16px

            @media(max-width: 576px)
              overflow-x: auto

            .brand-item
              padding: 0.5rem

              & > a
                display: block

                .brand
                  position: relative
                  text-align: center
                  padding: 0.5rem
                  border: 1px solid #E8EAEE
                  border-radius: 100%
                  overflow: hidden
                  white-space: nowrap
                  text-overflow: ellipsis

                  & > img
                    width: 100%

                  &:before
                    display: inline-block
                    padding-top: 100%
                    content: ' '
                    vertical-align: middle

                &.active
                  & > .brand
                    position: relative

                    &:after
                      position: absolute
                      display: flex
                      align-items: center
                      justify-content: center
                      left: 0
                      right: 0
                      top: 0
                      bottom: 0
                      background-color: rgba(238, 68, 68, 0.7)
                      font: normal normal normal 24px/1 "Material Design Icons"
                      text-rendering: auto
                      content: "\F012C"
                      color: #fff
                      border-radius: 100%
                      font-size: 36px

      & > .right
        padding: 0
        margin-left: 3rem
        border-top: 1px solid #eee
        @media(max-width: 576px)
          margin: 0

        & > .count
          position: relative
          padding: 1.5rem 1rem 0.5rem 1rem
          color: #333
          font-weight: 600
          z-index: 10

          & > .order
            position: absolute
            right: 1rem
            top: 50%
            color: #a5a5a5
            transform: translate(0, -50%)

            & > button
              color: #a5a5a5 !important
              border: 0
              outline: 0
              background-color: #fff

            & > ul.order-list
              position: absolute
              padding: 0
              margin: 0
              list-style: none
              border: 1px solid #eee
              border-radius: 5px
              top: 100%
              right: 0
              min-width: 100px
              background-color: #fff
              z-index: 100

              &.app
                & > li
                  padding: 0.1rem 0

              & > li
                padding: 0.25rem 0.5rem
                font-size: 14px

                & > button
                  display: block
                  width: 100%
                  background-color: #fff
                  padding: 0.25rem 0.75rem
                  color: #a5a5a5 !important
                  border: 0
                  outline: 0
                  text-align: left
                  font-size: 14px

                  &:hover
                    color: #333 !important

                  &.active
                    color: #333 !important

        .item
          display: block
          border: 1px solid transparent
          padding: 1rem
          @media(max-width: 576px)
            border: 0
            display: flex
            flex-wrap: wrap
            padding: 0 1rem

          & > .image
            padding: 2rem

            @media(max-width: 576px)
              padding: 1rem

            &.online
              padding: 0
              width: 100%
              height: 140px
              text-align: center
              line-height: 140px

              @media(max-width: 576px)
                flex: 0 0 auto
                border: 1px solid #F5F5F5
                width: 140px

              & > img
                width: 120px

            @media(max-width: 576px)
              flex: 0 0 auto
              border: 1px solid #F5F5F5
              width: 140px

            & > img
              width: 100%

          & > .info
            @media(max-width: 576px)
              flex-basis: 0
              flex-grow: 1
              max-width: 100%
              margin-left: 1rem
              position: relative

            & > .brand
              color: #a5a5a5

            & > .item-name
              color: #a5a5a5
              text-align: center
              @media(max-width: 576px)
                text-align: left
                font-weight: 500
                color: #333
                margin-top: 0.5rem

            & > .price
              text-align: center
              margin-top: 0.25rem
              color: #333
              font-weight: bold
              font-size: 16px
              @media(max-width: 576px)
                position: absolute
                text-align: left
                left: 0
                bottom: 0.5rem

          &:hover
            border: 1px solid #eee
            @media(max-width: 576px)
              border: 0

  & > .brand-search-container
    padding: 1rem 0

    & > .header
      position: relative
      padding: 1rem 0

      h5.title
        font-size: 24px
        color: #333

        & > a
          position: relative
          display: block
          width: 100%

          & > i
            position: absolute
            right: 0
            top: 50%
            transform: translate(0, -50%)

      .categories
        padding: 1rem 0

        & > .cat-rows
          padding: 0
          margin: 0
          border-top: 1px solid #E8EAEE
          border-left: 1px solid #E8EAEE
          @media (max-width: 576px)
            border-left: 0

          & > .cat
            position: relative
            margin: 0
            padding: 0
            border-right: 1px solid #E8EAEE
            border-bottom: 1px solid #E8EAEE

            .category-name
              overflow: hidden
              text-overflow: ellipsis
              white-space: nowrap

            a
              position: relative
              display: block
              width: 100%
              padding: 1rem
              font-size: 13px

            &.active
              a
                color: #EE4444 !important
                font-weight: 600

              i
                color: #EE4444 !important

      .tags
        & > span.badge
          position: relative
          z-index: 2
          padding: 0.5rem
          margin-right: 0.5rem

          &:last-child
            margin-right: 0

    & > .body
      padding: 1rem

      & > .item-container
        & > .header
          padding-top: 1.5rem

        & > .body
          padding: 1rem 0


.online-purchase
  overflow-x: hidden
  overflow-y: auto
  padding: 1rem 0
  background-color: #fff
  @media (max-width: 576px)
    padding: 0

  & > .online-purchase-window,
  & > .gift-purchase-window
    display: inline-block
    max-width: 500px
    width: 100%
    vertical-align: middle
    text-align: left
    background-color: #fff

    @media (max-width: 576px)
      width: 100%
      height: 100%

      &.h-auto
        height: auto

    & > .header
      position: relative
      height: 200px
      background: linear-gradient(180deg, #FFFFFF 0%, #EAEBFC 100%)

      & > a.close-btn
        position: absolute
        right: 0.5rem
        top: 0.5rem

      & > .brand-card
        position: absolute
        left: 50%
        top: 50%
        display: inline-block
        width: 150px
        height: 90px
        vertical-align: middle
        background-color: #fff
        line-height: 90px
        text-align: center
        border-radius: 8px
        z-index: 4
        transform: translate(-50%, -50%)

        &.cultureland
          &:before
            background-color: #E20000

        &.google_gift
          &:before
            background-color: #232323

        &.happy_money
          &:before
            background-color: #9FBE0E

        &.teencash
          &:before
            background-color: #0068B7

        &.book_and_life
          &:before
            background-color: #ED1CB2

        &.funny
          &:before
            background-color: #02AED2

        &.tcash
          &:before
            background-color: #262626

        &:before
          position: absolute
          left: 0
          width: 6px
          top: 0
          bottom: 0
          content: ' '
          border-bottom-left-radius: 8px
          border-top-left-radius: 8px

        & > img
          width: 70%

      & > .brand-card-shadow-1
        position: absolute
        width: 130px
        height: 70px
        box-shadow: 0 4px 16px rgba(154, 162, 173, 0.3)
        border-radius: 8px
        top: 50%
        left: 50%
        transform: translate(-50%, -75%)
        background-color: #F8F8F9
        content: ' '
        z-index: 3

      & > .brand-card-shadow-2
        position: absolute
        width: 110px
        height: 50px
        box-shadow: 0 4px 16px rgba(154, 162, 173, 0.3)
        border-radius: 8px
        top: 50%
        left: 50%
        background-color: #FCFCFC
        transform: translate(-50%, -120%)
        content: ' '
        z-index: 1
    //background-color: red


    & > .body
      & > .content
        padding: 1.5rem

        & > h4
          font-size: 22px
          color: #333

        & > h5
          font-size: 16px
          color: #333
          font-weight: 600

      .sum
        border-top: 1px solid #E8EAEE

      a.payment
        display: block
        border: 2px solid #E8EAEE
        border-radius: 6px

        &.active
          border: 2px solid #EE4444

          .name
            color: #EE4444

        &:before
          display: inline-block
          padding-top: 100%
          content: ' '
          vertical-align: middle

        &.pt-60p
          &:before
            padding-top: 60%

      .checkbox
        position: relative
        top: -2px
        display: inline-block
        width: 22px
        height: 22px
        text-align: center
        line-height: 22px
        border: 1px solid #E8EAEE
        vertical-align: middle
        border-radius: 3px

        &.checked
          border: 1px solid #EE4444

          & > i
            color: #EE4444

    & > .footer
      height: 60px

.online-purchase
  & > .gift-purchase-window
    & > .header
      height: auto
      padding: 2rem 0
      background: #fff

      & > img
        width: 70%

.brand-app-index
  background-color: #F6F8FA

  .header-top-banner
    position: fixed
    left: 0
    right: 0
    top: 0
    background-color: #007AFF
    height: 60px
    line-height: 60px
    z-index: 100

    img.banner
      height: 28px
      @media (max-width: 576px)
        height: 60%

    a.to-ok-goodpin-service
      border: 1px solid #3DF5A7
      border-radius: 8px
      color: #3DF5A7 !important
      font-size: 13px
      padding: 0.35rem 0.5rem
      margin-left: 0.75rem

      &.no-border
        border: 0

    .coin1
      position: absolute
      left: 0
      top: -22px

    .coin2
      position: absolute
      right: 0
      bottom: -16px

  section.header
    position: fixed
    left: 0
    right: 0
    top: 60px
    height: 60px
    line-height: 60px
    background-color: #fff
    z-index: 100
    text-align: center

    & > a.logo
      & > img
        width: 100px

    & > button.right-btn
      position: absolute
      right: 0.5rem
      top: 50%
      transform: translate(0, -50%)
      border: 0
      outline: 0
      background-color: #fff

    & > button.left-btn
      position: absolute
      left: 0
      top: 50%
      transform: translate(0, -50%)
      border: 0
      outline: 0
      background-color: #fff

      & > i
        color: #333

  section.body
    position: relative
    top: 120px

    & > .app-search
      position: relative
      padding: 0 1rem

      & > .input-control
        border: 1px solid #e2e2e2
        border-radius: 5px
        height: 50px
        background-color: #fff

        & > input.no-border
          height: 50px
          line-height: 50px
          padding: 0 0.5rem
          width: calc(100% - 40px)
          border: 1px solid transparent
          outline: 0
          box-shadow: none
          background-color: transparent

        & > a.search-btn
          position: absolute
          right: 2rem
          top: 50%
          transform: translate(0, -50%)

    & > .inner
      padding: 1rem

      h3.title
        font-size: 24px
        font-weight: 800

      .brands
        margin-left: -0.5rem
        margin-right: -0.5rem

        .brand-items
          padding: 0.5rem

          a.item
            position: relative
            display: inline-block
            background-color: #fff
            width: 100%
            border-radius: 10px
            box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1)

            &:before
              display: inline-block
              padding-top: 55%
              content: ' '

            & > .img
              position: absolute
              width: 100%
              padding: 0 1rem
              left: 50%
              top: 50%
              transform: translate(-50%, -50%)
              text-align: center

              &.bhcbhc, &.pizzaalvolo, &.HLC001, &.bonjuk
                padding: 0 2rem

      .categories
        margin-left: -0.5rem
        margin-right: -0.5rem

        .category-items
          padding: 0.5rem

          & > a.item
            position: relative
            display: inline-block
            width: 100%

            &:before
              display: inline-block
              padding-top: 100%
              content: ' '

            & > .img
              position: absolute
              width: 100%
              border-radius: 100%
              background-color: #f6f8fa
              left: 50%
              top: 50%
              transform: translate(-50%, -50%)
              text-align: center

          & > .text
            font-size: 3vw
