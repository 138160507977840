$primary-color: #00B3CE
#goodpin-main.ok
  @import './_main'

  & > .goodpin-container,
  & > .brand-app-index
    background-color: #fff

    &.brand-app-index
      section.header
        & > a.to-goodpin
          position: absolute
          display: block
          line-height: normal
          left: 1rem
          border: 1px solid #EE4444
          color: #EE4444 !important
          border-radius: 16px
          padding: 0.35rem 0.75rem
          top: 50%
          font-size: 15px
          font-weight: 600
          transform: translate(0, -50%)

    &.introduce-index
      .bg-gray
        background-color: #fff

      .bg-pink
        background-color: #F8FAFC !important

      .text-danger
        color: #00B3CE !important

      a.btn-link
        display: inline-block
        margin-top: 1rem !important
        background-color: #333
        color: #fff !important
        padding: 1rem 2rem
        border-radius: 6px

    &.patent-index
      & > .patent-content
        .patent-inner-content
          background-color: #F1F5FA
          padding: 48px
          border-radius: 20px

      & > .patent-process-content
        background-color: #F8FAFC

        .label-box
          background-color: #fff

        .text-danger
          color: #00B3CE !important

    &.howto-index
      .vertical-menu
        margin-top: 2rem

        .mx-1280 > .tab-indexes
          & > .col.active
            border-bottom-color: #00B3CE

            & > a
              color: #00B3CE !important
              font-weight: 800

      .howto-container
        background-color: #F1F5FA
        padding: 48px
        border-radius: 16px

        .tab-label
          background-color: #00B3CE
          color: #fff

        .col
          text-align: center

          img.w-100
            width: 60% !important

        .text-danger
          color: #00B3CE !important

    &.notice-index
      .search-container
        background-color: #fff !important

        .search-group
          background-color: #FAFAFA !important
          padding: 0 !important

          input.search-control
            background: transparent
            padding: 0.75rem 2rem

          button.search-magnify
            right: 0 !important
            height: 100%
            padding: 0 36px
            border-radius: 24px
            background-color: #00B3CE !important
            border: 0 !important
            color: #fff !important

      .notice-container
        border-top: 2px solid #00B3CE !important
        text-align: center

        .notice-row
          text-align: left

          &.odd
            background-color: #F8FAFC

        button.more-btn
          display: inline-block
          max-width: 130px
          width: 100%
          border-radius: 24px

    &.certificate-index
      .input-container
        margin: 2rem auto

      .btn-danger
        background-color: #00B3CE !important
        color: #fff !important
        border-color: #00B3CE !important

    &.brand-index
      & > section.header.mx-1280
        margin-top: 2rem

        & > .search
          background-color: #F8FAFC


    & > .service
      padding: 2rem 0
      background-color: #F5F5F6
      border-bottom: 1px solid #F5F5F6

      h3.service-title
        font-size: 24px
        color: #333
        @media(max-width: 574px)
          font-size: 18px

      .number
        font-size: 28px
        @media(max-width: 574px)
          font-size: 20px

    & > .footer
      padding: 3rem 0

    & > .goodpin-page-title
      //max-width: 1200px
      //width: 100%
      margin: 0 auto
      height: 250px
      background: url('../../assets/images/ok/title_pattern.png') no-repeat center center
      background-size: cover
      text-align: center

      &:before
        display: inline-block
        content: ' '
        vertical-align: middle
        height: 100%

      & > .title-container
        display: inline-block
        vertical-align: middle
        font-size: 42px
        @media (max-width: 576px)
          font-size: 8vw

    & > .content-container
      border-top: 1px solid #f5f5f6
      padding: 3rem 0

      h4.content-title
        color: #333
        font-weight: 600
        font-size: 24px

      .label-box
        width: 250px
        display: inline-block
        padding: 1.5rem 0
        text-align: center
        background-color: #f5f5f6

      .right-arrow
        position: absolute
        right: 1rem
        top: 50%
        transform: translate(0, -50%)

        & > i
          font-size: 24px
          font-weight: 200
          color: #888

      .arrow-down
        position: absolute
        bottom: -35px
        left: 50%
        transform: translate(-50%, 0)

      .search-container
        padding: 3.5rem 0
        background-color: #FFF2F2
        border-radius: 10px
        text-align: center

        & > .search-group
          position: relative
          display: inline-block
          max-width: 500px
          width: 100%
          background-color: #fff
          border-radius: 20px
          padding: 0.5rem 1rem

          input.search-control
            display: block
            width: calc(100% - 20px)
            outline: none
            border: 0

          button.search-magnify
            position: absolute
            right: 0.5rem
            top: 50%
            background-color: transparent
            border: 0
            outline: 0
            box-shadow: none
            font-size: 20px
            transform: translate(0, -50%)

    .notice-container
      .notice-row
        border-top: 1px solid #eee

        .notice-title
          display: block
          padding: 1.5rem 1rem

          & > h6
            margin: 0

        .notice-content
          border-top: 1px solid #eee
          padding: 2rem 1rem

      button.more-btn
        display: block
        background-color: #fff
        width: 100%
        border: 1px solid #eee
        color: #888
        text-align: center
        padding: 1rem
        box-shadow: none
        outline: none
        font-size: 16px
        border-radius: 5px


  header.goodpin-header
    position: relative

    .header-top-banner
      position: relative
      background-color: #007AFF
      height: 60px
      line-height: 60px
      overflow: hidden

      img.banner
        height: 28px
        @media (max-width: 576px)
          height: 60%

      .coin1
        position: absolute
        left: 0
        top: -22px

      .coin2
        position: absolute
        right: 0
        bottom: -16px

    a.border-round-button
      border: 1px solid $primary-color
      background-color: #fff
      color: $primary-color !important

      &:hover
        background-color: $primary-color
        color: #fff !important

    .sub-menu-background
      position: fixed
      top: 0
      bottom: 0
      left: 0
      right: 0
      background-color: rgba(0, 0, 0, 0.5)
      padding: 1rem
      z-index: 10

      .sub-menu-white-background
        position: absolute
        top: 0
        bottom: 200px
        left: 0
        right: 0
        height: 320px
        background-color: #fff


    .menu-container
      position: relative
      display: flex
      width: 100%
      margin: 0 auto
      height: 80px
      background-color: #fff
      z-index: 12
      align-items: center

      ul.header-menu
        padding: 0
        margin: 0
        list-style: none
        width: 100%

        & > li
          position: relative
          display: inline-block
          padding: 0 2rem

          &:last-child
            padding-right: 0

          & > a
            display: block
            font-size: 16px
            color: #333
            font-weight: 600
            cursor: pointer

            &.border-round-button
              display: inline-block
              line-height: 30px
              border: 1px solid $primary-color
              padding: 0.5rem 1rem
              border-radius: 8px
              color: #fff !important
              background-color: $primary-color

              &:hover
                background-color: $primary-color
                color: #fff !important

          & > .header-menu-child-container
            position: absolute
            top: 100%

            ul.header-menu-child
              padding: 0
              margin: 0
              list-style: none

              & > li
                line-height: inherit !important

                & > a
                  display: block
                  font-size: 14px
                  color: #333
                  font-weight: 500
                  cursor: pointer
                  padding: 0.5rem 0
                  text-align: left

  footer.goodpin-footer
    background-color: #fff
    padding: 3rem 0

  .square
    &:before
      display: block
      content: ''
      padding-top: 100%


  .tab-indexes
    &.row
      margin: 0

    & > .col
      padding: 0.5rem 0
      margin: 0
      border-bottom: 1px solid #eee

      & > a
        color: #888 !important

      &.active
        border-bottom: 2px solid #333

        & > a
          color: #333 !important
          font-weight: 600

  .tab-content
    .tab-section
      padding: 2rem 0 6rem 0
      border-top: 1px solid #EEEEEE

      &:first-child
        border-top: 0

      h3.tab-title
        padding: 4rem 0 6rem 0
        font-weight: 700

    h5.tab-sub-title
      font-size: 18px
      font-weight: 600

    p.tab-comment
      color: #888
      font-size: 12px

  .tab-label
    border-radius: 5px
    padding: 0.25rem 0.75rem
    background-color: #FFF2F2
    font-weight: 600
    color: #EE4444

  .thumb-box
    position: relative
    display: inline-block
    width: 75px
    height: 75px
    border: 1px solid #eee
    border-radius: 10px

    & > img
      position: absolute
      padding: 0.5rem
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)

  .right-button
    position: absolute
    right: 0
    top: 50%
    transform: translate(0, -50%)
    font-size: 0.75rem
    color: #888

  .split-bar
    background-color: #F6F8FA
    height: 0.5rem

  .full-window
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    z-index: 100
    background-color: #fff

    &.z-10000
      z-index: 10000

    &.bg-light-dark
      background-color: rgba(0, 0, 0, 0.7)

    &.window-middle
      &:before
        display: inline-block
        height: 100%
        content: ' '
        vertical-align: middle

  .control-qty
    display: inline-block
    border: 1px solid #E8EAEE
    border-radius: 4px

    & > span.count
      display: inline-block
      width: 48px
      height: 36px
      line-height: 36px
      text-align: center

    & > a.control-btn
      display: inline-block
      width: 36px
      height: 36px
      text-align: center
      line-height: 36px

      &:first-child
        border-right: 1px solid #e8eaee

      &:last-child
        border-left: 1px solid #e8eaee

  .plus-10-btn
    display: inline-block
    width: 48px
    height: 36px
    line-height: 36px
    text-align: center
    color: #EE4444 !important
    background-color: #FEF0F0
    border-radius: 4px

  .round-btn
    position: relative
    display: inline-block
    height: 24px
    width: 24px
    border: 1px solid #E8EAEE
    border-radius: 100%
    vertical-align: middle

    &:after
      position: absolute
      width: 8px
      height: 8px
      display: inline-block
      background-color: #E8EAEE
      border-radius: 100%
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      content: ' '

    &.active
      border: 1px solid #EE4444
      background-color: #EE4444

      &:after
        background-color: #fff

  .border-line
    height: 1px
    background-color: #eeeeee

  a.border-round-button
    border: 1px solid $c-red
    padding: 0.5rem 1rem
    border-radius: 20px
    color: $c-red !important

    &:hover
      background-color: $c-red
      color: #fff !important

  .policy-window
    padding: 1rem
    text-align: center
    overflow-x: hidden
    overflow-y: auto

    & > .policy-container
      display: inline-block
      max-width: 500px
      width: 100%
      background-color: #fff
      vertical-align: middle
      text-align: left


      & > .header
        position: relative
        padding: 1rem

        & > h5
          margin: 0
          padding: 0

        & > a.close-btn
          position: absolute
          right: 1rem
          top: 50%
          transform: translate(0, -50%)

      & > .body
        padding: 1rem

  .app-margin
    margin-top: 60px !important

  section.app-header
    position: fixed
    text-align: center
    height: 60px
    line-height: 60px
    top: 0
    left: 0
    right: 0
    background-color: #fff
    z-index: 100

    & > span.header-text
      font-weight: bold

    & > .right-btn
      position: absolute
      right: 1rem
      top: 50%
      transform: translate(0, -50%)

    & > .left-btn
      position: absolute
      left: 1rem
      top: 50%
      transform: translate(0, -50%)

  .app-window
    background-color: #F6F8FA

    .title
      color: #606978
      background-color: #F6F8FA
      padding: 0.5rem 1rem
      font-size: 14px
      font-weight: 500

    ul.preference-list
      padding: 0
      margin: 0
      list-style: none

      & > li
        & > button, & > a
          color: #333 !important
          display: block
          text-align: left
          width: 100%
          padding: 1rem
          border: 0
          outline: 0
          background-color: #fff

  .family-site-container
    position: relative

    a.family-site-btn
      display: inline-block
      padding: 0.5rem 1rem
      border: 1px solid #e2e2e2
      border-radius: 5px
      font-size: 14px

    ul.family-site-list
      position: absolute
      padding: 0
      margin: 0
      list-style: none
      bottom: 110%
      background-color: #fff
      width: 100%
      border-radius: 5px
      border: 1px solid rgb(0 0 0/0.1)
      box-shadow: 0 20px 25px -5px rgb(0 0 0/0.1), 0 8px 10px -6px rgb(0 0 0/0.1)

      & > li
        & > a
          display: block
          padding: 0.5rem 1rem
          text-align: left
          font-size: 14px

          &:hover
            background-color: rgba(0, 0, 0, 0.05)

  .online-purchase
    overflow-x: hidden
    overflow-y: auto
    padding: 1rem 0
    background-color: #fff
    @media (max-width: 576px)
      padding: 0

    & > .online-purchase-window,
    & > .gift-purchase-window
      .content
        a.payment.active
          border-color: #02AED2 !important

          .name
            color: #00B3CE !important

      .text-danger
        color: #00B3CE !important

      .round-btn.active
        background-color: #02AED2 !important
        border-color: transparent !important

      input.form-control
        caret-color: #00B3CE !important

      .checkbox.checked
        border-color: #00B3CE !important

        & > i
          color: #00B3CE !important

      .border-danger
        border-color: #00B3CE !important

      .bg-danger
        background-color: #00B3CE !important

      .plus-10-btn
        background-color: #00B3CE !important
        color: #fff !important

  .alert.alert-danger
    background-color: rgba(23, 202, 222, 0.05)
    border-radius: 8px
    border-color: #00B3CE