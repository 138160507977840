@import '../media'
section#visual
  background-color: #F4F9FB

  .btnbox
    a
      &.btn-google
        background: url(../../assets/images/ok/page/img_btn_googleplay.svg) no-repeat center
        background-size: auto 60px
        margin-right: 11px
        width: 155px
        height: 55px

      &.btn-appstore
        background: url(../../assets/images/ok/page/img_btn_appstore.svg) no-repeat center
        background-size: auto 60px
        width: 155px
        height: 55px

  .imgbox

    &::after
      background: transparent
      background-size: 100% auto
      z-index: 3

    .mask
      background: transparent !important

    .circle

      .circle1
        background: transparent

      .circle2
        background: transparent

      .circle3
        background: transparent

      .circle4
        background: transparent

      .circle5
        background: transparent

    .device
      top: -1.2vw
      left: 8vw
      scale: 1.05
      overflow: visible
      @include respond(desktop-lg)
        top: -1.6vw
      @include respond(desktop)
        top: -5vw
        left: 16vw
        width: 84vw
      @include respond(tablet)
        top: 1.5vw
        left: 14vw
        width: 72vw
        scale: 1
      @include respond(mobile)
        top: 11vw
        left: 20vw
        scale: 1.5

    .mask
      position: relative
      width: 750px
      height: 727px


      &::before
        left: 0
        background: transparent
        //top: -500px
        background-size: auto 100%
        z-index: 0

      &::after
        content: ' '
        right: 0
        background: transparent
        background-size: auto 100%
        z-index: 0

section#enjoy
  background: #00B3CE

  .imgbox
    background: url(../../assets/images/ok/page/bg_enj_mask.png) no-repeat bottom

    .mask
      background: transparent

  .txtbox
    & > .btnbox > a.btn.btn-gray
      background-color: #333333

      & > em.text-gray
        color: #fff !important

section#security
  .imgbox
    background: url(../../assets/images/ok/page/bg_sec.png) no-repeat
    background-size: 100% auto

    .browser
      background: url(../../assets/images/ok/page/img_sec_01.png) no-repeat
      background-size: 100% auto

    .lock
      top: 140px
      left: 230px
      background: url(../../assets/images/ok/page/img_sec_02.png) no-repeat
      background-size: 100% auto
      @include respond(tablet)
        top: 50%
        left: 50%
        transform: translate(-50%, -60%)
      @include respond(mobile)
        top: 50%
        left: 50%
        transform: translate(-50%, -60%)

    .num
      top: 250px
      left: 190px
      background: url(../../assets/images/ok/page/img_sec_03.png) no-repeat
      background-size: 100% auto
      @include respond(tablet)
        top: 50%
        left: 50%
        transform: translate(-50%, 130%)
      @include respond(mobile)
        top: 50%
        left: 50%
        transform: translate(-50%, 130%)

  .txtbox
    & > .btnbox > a.btn.btn-gray
      background-color: #333333

      & > em.text-gray
        color: #fff !important

section#payment
  background-color: #EDFBFD

  .imgbox
    .circle-card
      background: url(../../assets/images/ok/page/img_pay_01.png) no-repeat
      background-size: 100% auto
      @include respond(tablet)
        top: 7vw
        left: 16vw
        @include size-same(26vw)
      @include respond(mobile)
        top: 7vw
        left: 16vw
        @include size-same(26vw)


    .circle-phone
      background: url(../../assets/images/ok/page/img_pay_02.png) no-repeat
      background-size: 100% auto

      @include respond(tablet)
        top: 48vw
        left: 52vw
        @include size-same(26vw)
      @include respond(mobile)
        top: 48vw
        left: 52vw
        @include size-same(26vw)


  .txtbox
    .tag
      strong
        color: #3851E7 !important

footer
  .customer
    background-color: #212B4E !important
    @include respond(mobile)
      padding: 36px 16px

    & > .inner
      background-color: #344069
      padding: 48px
      border-radius: 24px
      display: flex
      align-items: center
      @include respond(mobile)
        display: block

      strong
        padding: 0
        flex: 1
        color: #E1E4EF
        vertical-align: middle
        @include respond(mobile)
          display: block
          font-size: 24px

      & > div
        flex: 1
        @include respond(mobile)
          display: block

      & > div > a.text-danger
        color: #17CADE !important
        span
          @include respond(mobile)
            width: 30px
            height: 25px
            background-size: 70% !important
            margin-right: 0

        & > span
          background: url(../../assets/images/page/ico_call_ok.svg) no-repeat 0 5px

      & > div > p
        color: #C2C7D8

        & > span
          color: #E1E4EF

  .company
    background-color: #212B4E

    & > .inner
      & > p
        color: #C6CCE2

      .fmenu
        & > a
          color: #9EA3B7 !important

      .family-site-container
        & > a.family-site-btn
          border-color: #344069 !important
          color: #C6CCE2 !important
