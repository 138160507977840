@function tint($color, $deep-level)
  @return mix(#fff, $color, (10-$deep-level)/10*100%)

@function fade($color, $fade-level)
  @return fade-out($color, (1-$fade-level))

$c-purple: #8950fc
$c-purple-blue: #7366ff
$c-theme-blue: #6387f9
$c-red: #f2460d
$c-orange: #ff9933
$c-green: #5cb85c
$c-blue: #2196F3
$c-grey: #8c8c8c
$c-grey-light: #e9eaf0
$c-white: #fff

$c-primary: $c-theme-blue
$c-secondary: #bed6d5
$c-success: #5cb85c
$c-danger: #f2460d
$c-warning: #ff9933
$c-info: #7ebcfa
$c-dark: #4e5d6c

//$c-primary-back: #8950FC1a
$c-primary-back: #6387f91a
$c-secondary-back: #bed6d51a
$c-success-back: #5cb85c1a
$c-danger-back: #f2460d1a
$c-warning-back: #ff99331a
$c-info-back: #7ebcfa1a
$c-dark-back: #4e5d6c1a
$c-grey-back: #8080801a

$c-login-face: #f2c98a
$c-back-lightblue: #f3f5f9
$c-back-th: #f3f3f7
$c-back-light: #fbfbff
$c-back: #f8f8f8

$c-input-disabled: #f5f7f9
$c-input-border: #efefef

$c-text: #0b254b
$c-text-light: #b5b5c3
$c-text-grey-7: #2b2b2b99
$c-text-grey: #839099
$c-text-darkgrey: #5f6567
$c-text-ignore: #9fafb7
$c-text-dark: #181c32

$f-eng: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace

$theme-colors: ("primary": $c-primary, "success": $c-success, "warning": $c-warning, "danger": $c-danger, "info": $c-info, "dark": $c-dark, "secondary": $c-secondary)
$body-bg: $c-back