.introduce-index
  .full-column
    &:before
      display: inline-block
      content: ' '
      vertical-align: middle
      height: 100%

  .text-container
    display: inline-block
    max-width: 500px
    padding: 1rem
    text-align: left
    vertical-align: middle

    & > h4
      font-size: 29px
      color: #333
      font-weight: bold

      @media (max-width: 576px)
        font-size: 7vw

  .circle
    position: relative
    display: inline-block
    max-width: 280px
    width: 100%
    border-radius: 100%
    background-color: #fff

    & > .circle-container
      position: absolute
      display: block
      text-align: center
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      padding: 1rem
      width: 90%
      font-size: 0.75rem
      @media (min-width: 768px)
        width: auto
        font-size: 1rem

      & > img
        width: 60px
        @media (min-width: 768px)
          width: 100%