@charset "UTF-8";

* {
  @include box-sizing;

  &::before,
  &::after {
    @include box-sizing;
  }
}

button,
input,
select,
textarea {
  font-family: $font-stat;
  cursor: pointer;
  vertical-align: middle;
}

img {
  outline: none;
  vertical-align: middle;
  max-width: 100%;
}

small {
  font-size: 1.2rem;
}

::-moz-selection {
  background: $color-light-pink;
}

::selection {
  background: $color-light-pink;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

textarea {
  caret-color: $color-primary;
  resize: none;

  &:focus {
    outline: 0;
  }
}

input {
  caret-color: $color-primary;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include placeholder() {
    color: $color-text-secondary;
  }

  &:focus {
    outline: 0;
  }
}

button {
  &:focus {
    outline: 0;
  }
}

/* align */
.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.inline-block {
  display: inline-block !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/* table */
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: $color-white;

  caption {
    @include sr-only;
  }

  td, th {
    border: 1px solid lighten($color-black, 94%);
    vertical-align: middle;
    padding: 10px 15px;
    font-size: 1.3rem;
    text-align: center;
  }

  th {
    font-weight: bold;
    background-color: lighten(#D1EDFE, 7%);
  }
}

.tbl-col {
  thead {
    th {
      border-bottom: 0;
    }
  }
}

.tbl-sm {
  td, th {
    font-size: 1.2rem;
    text-align: left;
  }
}

/* form */
.form-text {
  display: block;
  font-size: 15px;
  font-weight: 300;
  color: $color-text-regular;
  margin-top: .25rem;
  @include respond(mobile) {
    font-size: 13px;
  }
}

.form-counter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 122px;

  input {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1 1 54px;
    -ms-flex: 1 1 54px;
    flex: 1 1 54px;
    height: 34px;
    padding: 0 17px;
    line-height: 34px;
    border: 0;
  }

  button {
    position: relative;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1 1 34px;
    -ms-flex: 1 1 34px;
    flex: 1 1 34px;
    @include rounded(50%);

    &:disabled::before,
    &:disabled::after {
      background: $color-text-secondary;
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      background: $color-text-primary;
    }

    &.minus-btn::before {
      top: 15px;
      left: 11px;
      width: 10px;
      height: 2px;
    }

    &.plus-btn::before,
    &.plus-btn::after {
      top: 15px;
      left: 11px;
      width: 10px;
      height: 2px;
    }

    &.plus-btn::after {
      @include rotate(90);
    }

    span {
      @include sr-only;
    }
  }
}

/* tabs */
.tabs {
  width: 100%;
  height: 50px;
  @include respond(mobile) {
    height: auto;
  }

  &::after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    height: 1px;
    background: $color-border-gray;
  }

  & > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  a {
    position: relative;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-size: 18px;
    line-height: 2.2;
    color: $color-text-regular;
    text-align: center;
    @include hover-active {
      color: $color-text-primary;
    }
    @include respond(mobile) {
      -webkit-box-flex: 0;
      -moz-box-flex: 0;
      -webkit-flex: 0 0 31%;
      -ms-flex: 0 0 31%;
      flex: 0 0 31%;
      padding-bottom: 3px;
      font-size: 15px;
    }

    &.active {
      color: $color-text-primary;
      font-weight: bold;

      &::before {
        position: absolute;
        bottom: 0;
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background: $color-text-primary;
      }
    }
  }
}

/* btn */
.btn {
  @include rounded(6px);
  @include transition(all .1s linear);
  @include respond(mobile) {
    height: 60px;
    padding: 0 15px;
    line-height: 58px;
    font-size: 15px;
  }

  &:active {
    @include transform(scale(0.99));
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &-white {
    color: $color-text-primary;
    background-color: $color-white;
    border-color: $color-border-gray;
    @include hover-active {
      border-color: darken($color-border-gray, 17%)
    }
  }

  &-red {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;
  }

  &-gray {
    color: $color-text-regular;
    background-color: $color-bg-gray;
    border-color: $color-bg-gray;
    @include hover-active {
      background-color: darken($color-bg-gray, 3%)
    }
  }

  &-pink {
    color: $color-text-primary;
    background-color: $color-lighter-pink;
    border-color: $color-lighter-pink;
    @include hover-active {
      background-color: darken($color-lighter-pink, 0.8%)
    }
  }

  &-transparent {
    color: $color-text-regular;
    background-color: $color-white;
    border-color: transparent;
  }

  &-reset {
    i {
      display: inline-block;
      @include size-same(16px);
      margin-right: 7px;
      background: url(../../assets/images/page/ico_reset.png) no-repeat;
      background-size: 16px;
      vertical-align: text-top;
    }
  }

  &-del {
    i {
      position: relative;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        content: '';
        width: 12px;
        height: 1px;
        margin-left: 8px;
        background-color: $color-text-regular;
      }

      &::before {
        @include rotate(45);
      }

      &::after {
        @include rotate(-45);
      }
    }

    span {
      @include sr-only;
    }
  }

  &-search {
    width: 84px;
    height: 65px;
    border: 0;
    background: url(../../assets/images/page/ico_search.svg) no-repeat center;
    background-size: 24px;
    @include respond(mobile) {
      width: 78px;
      height: 60px;
      background-size: 22px;
    }

    span {
      @include sr-only;
    }
  }

  &-arrow-red {
    i {
      display: inline-block;
      @include size-same(11px);
      margin-left: 8px;
      background: url(../../assets/images/page/ico_arrow_red.svg) no-repeat center;
      background-size: 100%;
    }
  }

  &-md {
    height: 34px;
    padding: 0 15px;
    line-height: 34px;
    font-size: 15px;
  }

  &-link {
    text-decoration: underline;
    vertical-align: baseline;
    @include hover-active {
      text-decoration: underline;
      & > em {
        text-decoration: underline;
      }
    }
  }

  strong {
    font-weight: bold;
  }

  & > em {
    font-style: normal;
    text-decoration: inherit;
  }
}

/* label */
label {
  &.req::after {
    content: '*';
    padding-left: 2px;
    color: $color-primary;
  }
}

.label {
  position: relative;
  display: inline-block;
  min-width: 70px;
  padding: 2px 12px;
  font-size: 1.2rem;
  white-space: nowrap;
  text-align: center;

  //checkbox
  &-chk {
    height: 26px;
    line-height: 26px;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;

      &:checked + span::before {
        background: url(../../assets/images/page/img_checkbox_active.png) no-repeat;
        background-size: 100%;
      }

      & + span::before {
        position: relative;
        top: -2px;
        content: '';
        display: inline-block;
        @include size-same(24px);
        margin-right: 10px;
        background: url(../../assets/images/page/img_checkbox.png) no-repeat;
        background-size: 100%;
        vertical-align: middle;
        @include transition(background-image .15s);
      }
    }

    & + & {
      margin-left: 10px;
    }
  }
}


.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}