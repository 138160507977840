@import "../stylesheets/color"
@import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
@import "https://fonts.googleapis.com/earlyaccess/notosanskr.css"
@import '../stylesheets/origin/index'
@import '../stylesheets/ok/index'

html
  height: 100%
  min-height: 100vh
  -webkit-overflow-scrolling: touch

  &.x-hidden
    overflow-x: hidden

body
  height: 100%
  font-weight: 500
  -webkit-overflow-scrolling: touch
  -webkit-font-smoothing: antialiased
  padding: 0
  margin: 0
  overflow-x: hidden
  background-color: #fff
  color: $c-text
  text-decoration: none !important
  font-size: 100% !important

  &.x-hidden
    overflow-x: hidden


  & > #goodpin
    height: 100%

a
  color: #525f7f !important
  text-decoration: none !important

  &:active, &:hover, &:focus
    color: #525f7f !important
    text-decoration: none !important

.mx-1280
  max-width: 1280px
  width: 100%
  margin: 0 auto
  padding: 0 1rem
  @media (max-width: 576px)
    padding: 0 2rem

  &.mobile-no-padding
    @media (max-width: 576px)
      padding: 0

.mx-800
  max-width: 800px
  width: 100%
  margin: 0 auto
  padding: 0 1rem
  @media (max-width: 576px)
    padding: 0 2rem

  &.mx-sm-hidden
    @media (max-width: 576px)
      width: 100%
      max-width: 100%
      margin: 0
      padding: 0

.mx-400
  max-width: 400px
  width: 100%
  margin: 0 auto
  padding: 0 1rem


