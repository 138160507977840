@charset "UTF-8";

//상품권로고
.thumbnail{
    overflow:hidden;
    .img{
        @include size-same(100%);
        img{
            @include size-same(100%);
        }
        span{
            @include sr-only;
        }
    }
}



//내상품권 form
.login-wrap{
    width:100%;
    max-width:460px;
    margin:0 auto -70px;
    @include respond(mobile){
        margin-bottom:0;
    }
    .form-combi{
        display:table;
        width:100%;
        > div{
            display:table-cell;
            vertical-align:top;
            &:last-child{
                width:150px;
                padding-left:10px;
                @include respond(mobile){
                    width:120px;
                }
                button{
                    padding:0;
                }
            }
        }
    }
    p{
        padding:35px 10px;
        line-height:22px;        
        margin-top:40px;        
        border-top:1px solid $color-border-gray;
        word-break:keep-all;
        @include respond(mobile){
            padding:30px 5px;
            line-height:19px;
            margin-top:30px;
        }
    }
}



//내상품권 list
.mypage-wrap{
    width:100%;
    max-width:1100px;
    margin:0 auto;
    > p{
        margin-bottom:19px;
        font-size:15px;
        font-weight:300;
        color:$color-text-regular;
        @include respond(tablet){
            font-size:13px;
        }
    }
    .lst{
        border-top:1px solid $color-border-gray;
        background:$color-white;
        li{
            a{
                display:inline-block;
                width:100%;
                padding:30px 20px;
                border-bottom:1px solid $color-border-gray;
                @include rwd-down(576){
                    padding:25px 0;
                }
                @include hover-active{
                    .link{
                        color:$color-primary;
                        @include transition(color .25s linear);
                        &::after{
                            border-color:$color-primary;
                        }
                    }
                }
            }
            .thumbnail{
                position:relative;
                float:left;
                @include size-same(75px);
                .img{
                    border:1px solid $color-border-gray;
                    @include rounded(27px);
                    overflow:hidden;
                    &.gift{
                        @include size-same(70px);
                        @include rounded(23px);
                    }
                }
                .ico-gift{
                    position:absolute;
                    right:-5px;
                    bottom:-5px;
                    @include size-same(28px);
                    background:$color-white;
                    *:not(span){
                        display:block;
                        @include size-same(100%);
                    }
                    em{
                        background:rgba($color-light-pink, 40%);
                        @include rounded(50%);
                    }
                    i{
                        background:url(../../assets/images/page/ico_gift.svg)no-repeat center;
                        background-size:16px;
                    }
                }
            }
            .txtbox{
                display: -webkit-box; 
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display:flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                @include rwd-down(576){
                    -webkit-box-direction: normal;
                    -moz-box-direction: normal;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-pack: start;
                    -moz-box-pack: start;
                    -webkit-justify-content: flex-start;
                    -ms-flex-pack: start;
                    justify-content: flex-start;
                }
                .info{
                    padding-top:15px;
                    padding-left:20px;
                    @include rwd-down(576){
                        padding-top:5px;
                    }
                    > span{
                        font-size:15px;
                        font-weight:300;
                        color:$color-text-regular;
                        @include rwd-down(576){
                            font-size:13px;
                        }
                    }
                    p{
                        margin-top:7px;
                        line-height:1.35;
                        @include rwd-down(576){
                            margin-top:4px;
                            font-size:15px;
                        }
                        em{
                            padding-right:7px;
                        }
                        strong{
                            font-size:19px;
                            font-weight:bold;
                            @include rwd-down(576){
                                font-size:17px;
                            }
                            span{
                                font-size:17px;
                                @include rwd-down(576){
                                    font-size:15px;
                                }
                            }
                        }
                    }
                }
                .link{
                    margin-top:32px;
                    font-size:15px;
                    color:$color-text-secondary;
                    @include arrow(8px, 8px, 2px, $color-text-secondary, 45);
                    @include rwd-down(576){
                        padding-left:20px;
                        margin-top:7px;
                        font-size:13px;
                        @include arrow(6px, 6px, 1px, $color-text-secondary, 45);
                    }
                    &::after{
                        margin:0 2px 1px 8px;
                        @include rwd-down(576){
                            margin:0 0 1px 5px;
                        }
                    }
                }
            }
        }
    }
}



//공지사항
//검색 form
.search{
    &-box{
        display: -webkit-box; 
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display:flex;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        height:175px;
        margin-bottom:34px;
        @include rounded(20px);
        background:$color-lighter-pink;
        @include respond(tablet){
            height:auto;
            margin-bottom:25px;
            padding:50px 0;
        }
        @include respond(mobile){
            padding:30px 20px;
        }
    }
    &-form{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        width:760px;
        @include rounded(100px);
        background:$color-white;
        @include transition(box-shadow .15s);
        @include rwd-down(992){
            width:88%;
        }
        @include respond(mobile){
            width:100%;
        }
        &:focus-within{
            @include box-shadow(0 0 2vw 1vw rgba($color-primary, .03));
        }
        > div:first-child{
            -webkit-box-flex: 1;
            -moz-box-flex: 1;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        }
    }
    &-input{
        padding:0 0 0 30px;
        border:0;
        background:transparent;
        @include respond(mobile){
            padding:0 0 0 25px;
        }
    }
}

.board-wrap{
    max-width:1100px;
    margin:0 auto;
    //공지사항 list
    .lst{
        border-top:1px solid $color-border-gray;
        li{
            &.no-data{
                padding:90px 0 100px;
                border-bottom:1px solid $color-border-gray;
                text-align:center;
                @include rwd-down(576){
                    padding:10vw 0 12vw;
                }
                &::before{
                    content:'';
                    display:block;
                    @include size-same(60px);
                    margin:0 auto 20px;
                    background:url(../../assets/images/page/ico_file_warning.svg)no-repeat;
                    background-size:100% auto;
                    @include rwd-down(576){
                        @include size-same(12vw);
                        margin-bottom:4vw;
                    }
                }
            }
            a{
                position:relative;
                display: -webkit-box; 
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display:flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                padding:33px 0 30px;
                border-bottom:1px solid $color-border-gray;
                @include arrow(10px, 10px, 2px, $color-text-secondary, 45);
                @include hover-active{
                    &::after{
                        border-color:$color-primary;
                    }
                }
                @include rwd-down(576){
                    padding:20px 0;
                }
                &::after{
                    position:absolute;
                    top:calc(50% - 5px);
                    right:22px;
                    @include rwd-down(576){
                        display:none;
                    }
                }
                & > div{
                    padding-right:55px;
                    padding-left:15px;
                    @include rwd-down(576){
                        padding-right:10px;
                        padding-left:5px;
                    }
                }
            }
        }
        strong{
            -webkit-box-flex: 0;
            -moz-box-flex: 0;
            -webkit-flex: 0 0 62px;
            -ms-flex: 0 0 62px;
            flex: 0 0 62px;
            padding:0 20px;
            font-size:19px;
            line-height:1.35;
            color:$color-text-regular;
            font-weight:bold;
            text-align:center;
            @include rwd-down(576){
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 0 37px;
                -ms-flex: 0 0 37px;
                flex: 0 0 37px;
                padding:0 10px;
                font-size:15px;
            }
        }
        .tit{
            font-size:19px;
            line-height:1.35;
            word-break:keep-all;
            @include rwd-down(576){
                font-size:15px;
            }
        }
        p{
            margin-top:9px;
            font-size:15px;
            font-weight:300;
            color:$color-text-secondary;
            @include rwd-down(576){
                margin-top:5px;
                font-size:13px;
            }
        }
    }
    //공지사항 view
    .view{
        .board{
            &-header{
                padding:36px 20px 29px 20px;
                line-height:1.35;
                border-top:1px solid $color-border-gray;
                border-bottom:1px solid $color-border-gray;
                @include rwd-down(576){
                    padding:23px 10px 20px;
                }
                p{
                    margin-bottom:9px;
                    font-size:29px;
                    font-weight:bold;
                    word-break:keep-all;
                    @include rwd-down(576){
                        margin-bottom:5px;
                        font-size:17px;
                    }
                }
                span{
                    font-size:17px;
                    font-weight:300;
                    color:$color-text-secondary;
                    @include rwd-down(576){
                        font-size:13px;
                    }
                }
            }
            &-body{
                padding:35px 20px;
                line-height:1.75;
                color:$color-text-regular;
                border-bottom:1px solid $color-border-gray;
                @include rwd-down(576){
                    padding:20px 10px;
                    font-size:15px;
                }
            }
            &-footer{
                margin-top:30px;
                text-align:center;
                @include rwd-down(576){
                    margin-top:25px;
                }
                a{
                    display:inline-block;
                    @include rwd-down(576){
                        height:50px;
                        padding:0 20px;
                        line-height:50px;
                        font-size:13px;
                    }
                    &.lst-btn i{
                        position:relative;
                        display:inline-block;
                        width:13px;
                        height:1px;
                        margin-right:10px;
                        background:$color-text-primary;
                        &::before,
                        &::after{
                            position:absolute;
                            content:'';
                            width:13px;
                            height:1px;
                            left:0;
                            background:$color-text-primary;
                        }
                        &::before{
                            top:-5px;
                        }
                        &::after{
                            bottom:10px;
                        }
                    }
                }
            }
        }
    }
}

//pagination
.pagination{
    padding-top:50px;
    text-align:center;
    @include rwd-down(576){
        padding-top:30px;
    }
    .page-item{
        display:inline-block;
        vertical-align:middle;
        &.active > a{
            @include rounded(3px);
            color:$color-white;
            font-weight:bold;
            background:$color-primary;
        }
    }
    .page-link{
        display:block;
        padding:8px 14px;
        @include hover-active{
            color:$color-primary;
        }
        @include rwd-down(576){
            padding:6px 10px 5px;
            font-size:15px;
        }
        span{
            @include sr-only;
        }
    }
    .btn{
        &-ico{
            padding:10px;
            @include rwd-down(576){
                padding:7px 5px;
            }
            &::before{
                content:'';
                display:block;
                height:12px;
            }
        }
        &-start{
            &::before{
                width:14px;
                background:url(../../assets/images/page/ico_arrow_gray.svg)repeat-x left;
                background-size:auto 100%;
            }
        }
        &-prev{
            margin-right:5px;
            &::before{
                width:10px;
                background:url(../../assets/images/page/ico_arrow_gray.svg)no-repeat center;
                background-size:auto 100%;
            }
        }
        &-end{
            &::before{
                width:14px;
                @include rotate(180);
                background:url(../../assets/images/page/ico_arrow_gray.svg)repeat-x left;
                background-size:auto 100%;
            }
        }
        &-next{
            margin-left:5px;
            &::before{
                width:10px;
                @include rotate(180);
                background:url(../../assets/images/page/ico_arrow_gray.svg)no-repeat center;
                background-size:auto 100%;
            }
        }
    }
}

//리스트더보기 버튼
.lst-more-btn{
    display:block;
    width:100%;
    height:70px;
    margin-top:-1px;
    line-height:68px;
    font-size:15px;
    color:$color-text-regular;
    text-align:center;
    border:1px solid $color-border-gray;
    @include arrow(8px, 8px, 1px, $color-text-regular, 135);
    @include rwd-down(576){
        height:50px;
        line-height:50px;
        font-size:13px;
    }
    &::after{
        margin:0 0 3px 10px;
    }
}



//자주묻는질문
.faq-wrap{
    max-width:1100px;
    margin:0 auto;
    .lst{
        border-top:1px solid $color-border-gray;
        li{
            border-bottom:1px solid $color-border-gray;
            word-break: keep-all;
            button{
                position:relative;
                width:100%;
                height:auto;
                padding:32px 50px 29px 68px;
                font-size:19px;
                line-height:1.5;
                text-align:left;
                border:0;
                background:transparent;
                @include arrow(10px, 10px, 2px, $color-text-secondary, 135);
                @include rwd-down(576){
                    padding:20px 45px 20px 37px;
                    font-size:15px;
                    @include arrow(8px, 8px, 1px, $color-text-secondary, 135);
                }
                &.active{
                    color:$color-primary;
                    &::after{
                        top:42px;
                        @include rotate(-45);
                        @include rwd-down(576){
                            top:30px;
                        }
                    }
                }
                &::before{
                    position:absolute;
                    left:25px;
                    content:'Q';
                    font-weight:bold;
                    @include rwd-down(576){
                        left:12px;
                        line-height:1.3;
                    }
                }
                &::after{
                    position:absolute;
                    top:37px;
                    right:27px;
                    @include rwd-down(576){
                        top:25px;
                        right:17px;
                    }
                }
                &:active{
                    @include transform(scale(1));
                }
            }
            .answer{
                position:relative;
                display:none;
                padding:33px 50px 30px 68px;
                line-height:1.75;
                color:$color-text-regular;
                border-top:1px solid $color-border-gray;
                background:$color-bg-gray;
                @include rwd-down(576){
                    padding:20px 40px 20px 37px;
                    font-size:15px;
                }
                &::before{
                    position:absolute;
                    left:26px;
                    content:'A';
                    font-size:19px;
                    font-weight:bold;
                    line-height:1.5;
                    color:$color-text-primary;
                    @include rwd-down(576){
                        left:13px;
                        font-size:15px;
                        line-height:1.8;
                    }
                }
            }
        }
    }
}



//상품권구매하기
.gift{
    //상품권 리스트
    &-lst{
        position:relative;
        &.sticky{
            position:sticky;
            top:0;
            z-index:9;
        }
        &-wrap{
            padding:40px 35px 36px;
            @include rounded(20px);
            background:$color-lighter-pink;
            overflow:hidden;
            @include respond(tablet){
                padding:4.3vw 40px 4vw 3vw;
            }
        }
        .swiper-slide{
            width:70px;
            margin:0 15px;
            font-size:13px;
            text-align:center; 
            @include hover-active{
                color:$color-primary;
                @include transition(color .1s linear);
                .thumbnail{
                    @include box-shadow(0 0 10px 3px rgba($color-primary, .07));
                    @include transition(box-shadow .15s linear);
                }
            }
            @include respond(tablet){
                width:auto;
                margin:0 2vw;
            }
            .thumbnail{
                position:relative;
                @include size-same(70px);
                @include rounded(23px);
                background:$color-white;
                @include respond(tablet){
                    @include size-same(11vw);
                    margin:0 auto;
                    @include rounded(3.9vw);
                }
            }
            p{
                margin-top:11px;
                @include respond(tablet){
                    margin-top:1.5vw;
                }
                @include respond(mobile){
                    font-size:3vw;
                }
            }
            &.active{
                color:$color-primary;
                font-weight:bold;
                .thumbnail{
                    &::after{
                        position:absolute;
                        top:0;
                        right:0;
                        bottom:0;
                        left:0;
                        content:'';
                        border:2px solid $color-primary;
                        @include rounded(23px);
                        @include respond(tablet){
                            @include rounded(3.9vw);
                        }
                        @include respond(mobile){
                            border-width:1px;
                        }
                    }
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev{
            top:0;
            bottom:0;
            width:50px;
            height:100%;
            margin-top:auto;
            background:$color-lighter-pink;
            @include transition(opacity .15s linear);
            @include respond(tablet){
                display:none;
            }
            &.swiper-button-disabled{
                @include opacity(0);
            }
        }
        .swiper-button-next{
            right:0;
            padding-right:8px;
            @include arrow(12px, 12px, 1px, $color-text-regular, 45);
        }
        .swiper-button-prev{
            left:0;
            padding-left:8px;
            @include arrow(12px, 12px, 1px, $color-text-regular, -135);
        }
        .swiper-scrollbar{
            right:5.5vw;
            bottom:0;
            left:0;
            display:none;
            width:auto;
            height:2px;
            background:transparent;
            @include respond(tablet){
                display:block;
            }
            @include respond(mobile){
                height:1px;
            }
            &-drag{
                background:$color-primary;
            }
        }
        .all-btn{
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            display:none;
            width:40px;
            height:100%;
            padding:0;
            border:0;
            @include rounded(0 20px 20px 0);
            background:$color-lighter-pink;
            z-index:9;
            @include arrow(9px, 9px, 2px, $color-text-regular, 135);
            @include respond(tablet){
                display:block;
            }
            span{
                @include sr-only;
            }
            &::after{
                position:absolute;
                top:10vw;
                left:16px;
            }
            &.is-active{
                &::after{
                    top:30px;
                    left:10px;
                    @include rotate(-45);
                }
                & + .gift-all{
                    @include respond(tablet){
                        display:block;
                    }
                }
            }
        }
        .gift-all{
            position:absolute;
            top:0;
            right:0;
            left:0;
            display:none;
            padding:15px 7.5vw 15px 20px;
            @include rounded(20px);
            @include box-shadow(0 5px 9px rgba(0,0,0,.07));
            background:$color-lighter-pink;
            z-index:8;
            > div{
                padding:10px 0;
                margin-bottom:7px;
                text-indent:5px;
                font-weight:bold;
            }
            a{
                position:relative;
                display:inline-block;
                padding:10px;
                margin:0 7px 7px 0;
                font-size:13px;
                border:1px solid rgba($color-primary,.07);
                @include rounded(6px);
                background:$color-white;
                z-index:10;
                &.active{
                    color:$color-primary;
                    font-weight:bold;
                    border-color:$color-primary;
                }
            }
        }
    }
    &-info{
        width:100%;
        max-width:1170px;
        margin:0 auto;
        h3{
            padding-top:103px;
            font-size:36px;
            font-weight:bold;
            text-align:center;
            @include rwd-down(576){
                padding-top:15vw;
                font-size:6vw;
            }
        }
    }
    //호라이즌 상품정보
    &-horizon{
        width:100%;
        max-width:1100px;
        margin:0 auto;
        .tit{
            padding-bottom:70px;
            text-align:center;
            @include rwd-down(576){
                padding-bottom:10vw;
            }
            p{
                margin:16px 0 32px;
                font-size:15px;
                font-weight:300;
                color:$color-text-regular;
                @include rwd-down(576){
                    margin:3vw 0 5vw;
                    font-size:13px;
                }
            }
            img{
                @include rounded(6px);
                @include box-shadow(7px 7px 15px rgba(0,0,0,.07));
                @include rwd-down(576){
                    width:80%;
                    @include rounded(3px);
                }
            }
        }
        .btnbox{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-direction: normal;
            -moz-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -moz-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            padding:20px 40px;
            margin-bottom:50px;
            @include rounded(15px);
            background:$color-bg-gray;
            @include rwd-down(992){
                padding:3vw 5vw 4vw;
                margin-bottom:25px;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            @include respond(mobile){
                padding:5vw;
            }
            dl{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-inline-flexbox;
                display: -webkit-inline-flex;
                display: inline-flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -moz-box-pack: start;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                -moz-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
            }
            dt{
                @include size-same(100px);
                @include rounded(50%);
                margin-right:20px;
                line-height:100px;
                font-weight:500;
                background:$color-white;
                text-align:center;
                @include rwd-down(576){
                    -webkit-box-flex: 0;
                    -moz-box-flex: 0;
                    -webkit-flex: 0 0 80px;
                    -ms-flex: 0 0 80px;
                    flex: 0 0 80px;
                    @include size-same(80px);
                    margin:0 3.5vw 0 4.5vw;
                    font-size:13px;
                    line-height:80px;
                }
            }
            span{
                display:inline-block;
                padding:20px;
                font-size:19px;
                font-weight:bold;
                @include respond(tablet){
                    padding:2vw 2vw 1.5vw;
                }
                @include rwd-down(576){
                    font-size:15px;
                }
            }
            .buy-btn{
                width:230px;
                @include rwd-down(992){
                    width:100%;
                    margin-top:4vw;
                }
                @include respond(mobile){
                    margin-top:6vw;
                }
            }
        }
        .detail{
            padding:54px 50px 68px;
            @include rounded(15px);
            border:1px solid $color-border-gray;
            font-weight:300;
            line-height:1.75;
            @include rwd-down(576){
                padding:8vw 5.5vw;
                font-size:13px;
            }
            strong{
                display:block;
                margin-bottom:18px;
                font-size:19px;
                font-weight:bold;
                @include rwd-down(576){
                    margin-bottom:3vw;
                    font-size:15px;
                }
            }
            .description{
                padding-bottom:58px;
                @include rwd-down(576){
                    padding-bottom:8vw;
                }
            }
            .notice{
                padding-top:60px;                
                color:$color-text-regular;
                border-top:1px solid $color-border-gray;
                @include rwd-down(576){
                    padding-top:8vw;
                }
                strong{
                    color:$color-primary;
                    i{
                        position:relative;
                        top:4px;
                        display:inline-block;
                        @include size-same(24px);
                        margin-right:9px;
                        background:url(../../assets/images/page/ico_notice.svg)no-repeat;
                        background-size:100%;
                        vertical-align:top;
                        @include rwd-down(576){
                            top:1px;
                            @include size-same(22px);
                            margin-right:5px;
                        }
                    }
                }
                li{
                    position:relative;
                    padding-left:12px;
                    @include rwd-down(576){
                        padding-left:8px;
                    }
                    &::before{
                        position:absolute;
                        top:13px;
                        left:0;
                        content:'';
                        width:5px;
                        height:1px;
                        background:$color-text-regular;
                        @include rwd-down(576){
                            top:10px;
                            width:4px;
                        }
                    }
                }
            }
        }
    }
    //허니콘 리스트
    &-honeycon{
        h3{
            margin-bottom:74px;
            @include respond(tablet){
                margin-bottom:10vw;
            }
        }
        ul{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display:flex;
            -webkit-box-direction: normal;
            -moz-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        li{
            width:33.333333%;
            padding:0 3%;
            margin-bottom:72px;
            text-align:center;
            @include respond(tablet){
                width:50%;
                padding:0 1%;
                margin-bottom:8vw;
            }
            .name{
                padding-top:19px;
                line-height:1.35;
                color:$color-text-regular;
                word-break:keep-all;
                @include respond(tablet){
                    padding-top:10px;
                    font-size:15px;
                }
                @include respond(mobile){
                    font-size:3.3vw;
                }
            }
            p{
                margin-top:12px;
                font-weight:bold;
                @include respond(tablet){
                    margin-top:5px;
                    font-size:15px;
                }
                strong{
                    font-size:23px;
                    @include respond(tablet){
                        font-size:17px;
                    }
                    @include respond(mobile){
                        font-size:15px;
                    }
                }
                span{
                    @include respond(tablet){
                        font-size:13px;
                    }
                }
            }
        }
    }
}



//상품권결제하기
.pay{
    &-wrap{
        width:100%;
        max-width:460px;
        margin:0 auto;        
        //수량선택 리스트
        .quantity-lst{
            li{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display:flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -moz-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                padding:12px 0 13px;
                @include respond(mobile){
                    padding:8px 0 7px;
                }
                @include rwd-down(374){
                    -webkit-box-direction: normal;
                    -moz-box-direction: normal;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    padding:12px 0;
                }
                &:first-child{
                    padding-top:0;
                }
                strong{
                    -webkit-box-flex: 0;
                    -moz-box-flex: 0;
                    -webkit-flex: 0 1 70px;
                    -ms-flex: 0 1 70px;
                    flex: 0 1 70px;
                    color:$color-text-regular;
                    @include rwd-down(374){
                        -webkit-box-flex: 0;
                        -moz-box-flex: 0;
                        -webkit-flex: 0 1 25px;
                        -ms-flex: 0 1 25px;
                        flex: 0 1 25px;
                    }
                }
                > div{
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-inline-flexbox;
                    display: -webkit-inline-flex;
                    display: inline-flex;
                    -webkit-box-direction: normal;
                    -moz-box-direction: normal;
                    -webkit-box-orient: horizontal;
                    -moz-box-orient: horizontal;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -webkit-box-pack: end;
                    -moz-box-pack: end;
                    -webkit-justify-content: flex-end;
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                    .plus-ten-btn{
                        color:$color-text-primary;
                    }
                }
            }
        }
        //수량선택 결과
        .quantity-result{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display:flex;
            -webkit-box-direction: normal;
            -moz-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -moz-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding-top:25px;
            margin-top:12px;
            margin-bottom:-10px;
            border-top:1px solid $color-border-gray;
            @include rwd-down(576){
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom:auto;
            }
            @include respond(mobile){
                padding-top:12px;
                margin-top:15px;
            }
            > div:first-child{
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 30%;
                -ms-flex: 0 1 30%;
                flex: 0 1 30%;
                button{
                    height:auto;
                    padding:10px 0 9px;
                    line-height:normal;
                }
            }
            > div:last-child{
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 70%;
                -ms-flex: 0 1 70%;
                flex: 0 1 70%;
                text-align:right;
                @include rwd-down(576){
                    text-align:left;
                }
                button{
                    height:auto;
                    padding:10px 30px 9px 15px;
                    line-height:normal;
                    margin:0 0 10px 7px;
                    @include rwd-down(576){
                        margin:10px 7px 0 0;
                    }
                    @include rwd-down(374){
                        margin:2vw 1vw 0 0;
                    }
                }
            }
        }
        //총 결제금액
        .pay-total{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display:flex;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -moz-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            > div{
                color:$color-primary;
                font-weight:bold;
                text-align:right;
                strong{
                    font-size:23px;
                    @include respond(mobile){
                        font-size:21px;
                    }
                }
            }
        }
        //결제수단
        .pay-method{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display:flex;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -moz-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            font-size:15px;
            text-align:center;
            li{
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 50%;
                -ms-flex: 0 1 50%;
                flex: 0 1 50%;
                &:first-child > div{
                    @include rounded(6px 0 0 6px);
                }
                &:last-child{
                    margin-left:-1px;
                    > div{
                        @include rounded(0 6px 6px 0);
                    }   
                }
                > div{
                    border:1px solid $color-border-gray;
                    cursor:pointer;
                    &.active{
                        position:relative;
                        background:$color-lighter-pink;
                        border-color:$color-primary;
                        z-index:1;
                        em{
                            color:$color-primary;
                            font-weight:bold;
                        }
                    }
                    &.pay-phone em{
                        background:url(../../assets/images/page/ico_phone.svg)no-repeat;
                        background-size:30px auto;
                        background-position:center top 21px;
                    }
                    &.pay-card em{
                        background:url(../../assets/images/page/ico_card.svg)no-repeat;
                        background-size:40px auto;
                        background-position:center top 26px;
                    }
                    em{
                        display:block;
                        padding:73px 0 20px;
                    }
                }
                p{
                    margin-top:14px;
                    font-size:13px;
                    font-weight:300;
                    color:$color-text-regular;
                    @include rwd-down(374){
                        margin-top:10px;
                    }
                    span{
                        @include rwd-down(374){
                            display:block;
                        }
                    }
                }
            }
        }
        //약관동의 및 버튼
        .pay-agree{
            margin-top:31px;
            @include respond(mobile){
                margin-top:25px;
            }
            > div{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display:flex;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -moz-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                font-size:15px;
                @include respond(mobile){
                    padding:0 5px;
                }
                a{
                    font-weight:300;
                    color:$color-text-regular;
                    @include respond(mobile){
                        font-size:13px;
                    }
                }
            }
            button{
                margin-top:38px;
                @include respond(mobile){
                    margin-top:30px;
                }
            }
        }
        //허니콘 상품설명
        .pay-detail{
            > p{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display:flex;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                padding-top:7px;
                @include rwd-down(374){
                    padding-top:5px;
                }
                strong{
                    color:$color-primary;
                    font-weight:bold;
                }
            }
            div{
                padding-top:25px;
                margin-top:25px;
                border-top:1px solid $color-border-gray;
                font-size:15px;
                font-weight:300;
                line-height:1.8;
                color:$color-text-regular;
                @include respond(mobile){
                    padding-top:5.5vw;
                    margin-top:6vw;
                    font-size:13px;
                }
            }
        }
    }
    &-section:not(.pay-tit,.pay-agree){
        padding:34px 30px 30px;
        margin-bottom:20px;
        @include rounded(15px);
        border:1px solid $color-border-gray;
        @include respond(mobile){
            padding:8vw 5.5vw 6.5vw;
            font-size:15px;
        }
        h4{
            margin-bottom:23px;
            font-size:19px;
            font-weight:bold;
            @include respond(mobile){
                margin-bottom:17px;
                font-size:17px;
            }
        }
    }
    &-tit{
        position:relative;
        padding:31px 0;
        margin-bottom:20px;
        font-size:24px;
        font-weight:bold;
        text-align:center;
        @include rounded(15px);
        background:$color-lighter-pink;
        @include respond(mobile){
            padding:7vw 0;
            font-size:6vw;
        }
        .back-btn{
            position:absolute;
            top:calc(50% - 17px);
            left:20px;
            @include size-same(34px);
            @include rounded(50%);
            background:$color-white;
            @include box-shadow(0 0 17px 0 rgba(0,0,0,.05));
            @include arrow(10px, 10px, 2px, $color-text-primary, -135);
            @include respond(mobile){
                @include size-same(9vw);
                top:calc(50% - 4.5vw);
                left:5vw;
                @include arrow(2.5vw, 2.5vw, 2px, $color-text-primary, -135);
            }
            &::after{
                margin:0 0 -2px 2px;
                @include respond(mobile){
                    margin:0 0 -0.8vw 0.5vw;
                }
            }
            span{
                @include sr-only;
            }
        }
    }
}



//이용안내
.guide{
    &-wrap{
        > div + div{
            border-top:1px solid $color-border-gray;
        }
        h3{
            padding:109px 0 108px;
            font-size:36px;
            font-weight:bold;
            text-align:center;
            @include rwd-down(576){
                padding:15vw 0;
                font-size:6vw;
            }
        }
        li{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display:flex;
            -webkit-box-direction: normal;
            -moz-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding-bottom:50px;
            @include respond(desktop-lg){
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                padding-bottom:100px;
                text-align:center;
            }
            @include rwd-down(576){
                padding-bottom:50px;
            }
            em{
                display:inline-block;
                @include size-same(45px);
                font-size:24px;
                line-height:46px;
                color:$color-primary;
                font-weight:500;
                text-align:center;
                background:$color-light-pink;
                @include rounded(6px);
                @include respond(desktop-lg){
                    margin-right:10px;
                    vertical-align:bottom;
                }
                @include rwd-down(576){
                    @include size-same(33px);
                    font-size:17px;
                    line-height:34px;
                }
            }
            strong{
                display:block;
                margin-top:15px;
                font-size:29px;
                line-height:1.4;
                font-weight:bold;
                @include rwd-down(576){
                    font-size:17px;
                }
            }
            p{
                margin-top:25px;
                font-weight:300;
                line-height:1.75;
                color:$color-text-regular;
                @include respond(desktop-lg){
                    margin-top:10px;
                    margin-bottom:30px;
                }
                @include rwd-down(576){
                    font-size:13px;
                }
            }
            span{
                display:block;
                @include respond(desktop-lg){
                    display:inline-block;
                }
                @include rwd-down(576){
                    display:block;
                }
            }
            .screen{
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 900px;
                -ms-flex: 0 1 900px;
                flex: 0 1 900px;
                height:420px;
                text-align:center;
                background:lighten($color-black, 97%);
                overflow:hidden;
                @include respond(desktop-lg){
                    -webkit-box-flex: 0;
                    -moz-box-flex: 0;
                    -webkit-flex: 0 1 420px;
                    -ms-flex: 0 1 420px;
                    flex: 0 1 420px;
                }
                @include respond(tablet){
                    -webkit-box-flex: 0;
                    -moz-box-flex: 0;
                    -webkit-flex: 0 1 50vw;
                    -ms-flex: 0 1 50vw;
                    flex: 0 1 50vw;
                }
                img{
                    @include respond(tablet){
                        width:42%;
                    }
                }
            }
        }
    }
}



//특허내역
.patent{
    &-wrap{
        > div{
            border-top:1px solid $color-border-gray;
        }
        h3{
            font-size:36px;
            line-height:1.48;
            font-weight:bold;
            @include respond(desktop){
                text-align:center;
            }
            @include rwd-down(576){
                font-size:6vw;
            }
        }
        .introduce{
            padding-top:60px;
            padding-bottom:10px;
            @include respond(desktop){
                position:relative;
            }
            @include rwd-down(576){
                padding-top:15vw;
                padding-bottom:0;
            }
            &::before{
                position:absolute;
                left:0;
                content:'';
                width:calc((100% - 1280px) / 2 + 260px);
                height:430px;
                background:$color-lighter-pink;
                @include respond(desktop){
                    top:15%;
                    left:-20px;
                    width:45%;
                    height:39vw;
                }
                @include rwd-down(576){
                    top:35vw;
                    width:55%;
                }
            }
            h3{
                padding:45px 0 66px;
                text-align:center;
                word-break:keep-all;
                @include rwd-down(576){
                    padding:0 0 7vw;
                }
            }
            > div{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: start;
                -moz-box-align: start;
                -webkit-align-items: flex-start;
                -ms-flex-align: start;
                align-items: flex-start;
                @include respond(desktop){
                    -webkit-box-direction: normal;
                    -moz-box-direction: normal;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }
            .imgbox{
                position:relative;
                width:25.79%;
                background:$color-white;
                @include box-shadow(7px 9px 20px rgba(0,0,0,.07));
                @include respond(desktop){
                    width:40%;
                    margin:3% auto 7%;
                }
                @include rwd-down(576){
                    width:53%;
                    @include box-shadow(5px 7px 15px rgba(0,0,0,.07));
                }
                &::after{
                    position:absolute;
                    right:-46px;
                    bottom:-34px;
                    content:'';
                    @include size-same(85px);
                    background:url(../../assets/images/page/bg_square_dots.png)no-repeat;
                    background-size:100%;
                    z-index:-1;
                    @include respond(desktop){
                        right:-7.5vw;
                        bottom:-5vw;
                        @include size-same(12vw);
                    }
                }
                img{
                    border:30px solid $color-white;
                    @include respond(desktop){
                        border-width:3vw;
                    }
                }
            }
            .txtbox{
                width:67.19%;
                @include respond(desktop){
                    width:90%;
                    margin:0 auto;
                }
                dl{
                    margin:24px 0;
                    @include rwd-down(576){
                        margin:9px 0;
                    }
                    *{
                        display:inline-block;
                        padding:8px 0 7px;
                        font-size:19px;
                        line-height:1.5;
                        @include rwd-down(576){
                            padding:5px 0;
                            font-size:15px;
                        }
                        &:nth-child(4),
                        &:nth-child(6){
                            @include rwd-down(992){
                                width:63%;
                            }
                            @include rwd-down(360){
                                width:60%;
                            }
                        }
                    }                
                    dt{
                        margin-right:20px;
                        font-weight:300;
                        color:$color-text-regular;
                        @include rwd-down(992){
                            margin-right:2vw;
                            vertical-align:top;
                        }
                    }
                    dd{
                        margin-right:50px;
                        @include rwd-down(992){
                            width:78%;
                            margin-right:auto;
                        }
                    }
                }
                p{
                    padding-top:11px;
                    font-weight:300;
                    letter-spacing:-0.8px;
                    line-height:1.75;
                    color:$color-text-regular;
                    @include rwd-down(576){
                        font-size:13px;
                    }
                }
            }
        }
        .process{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-direction: normal;
            -moz-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -moz-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding-top:120px;
            margin-top:120px;
            @include respond(desktop){
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -moz-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
            }
            @include rwd-down(576){
                padding-top:20vw;
                margin-top:15vw;
            }
            h3{
                @include respond(desktop){
                    margin-bottom:50px;
                }
                @include rwd-down(576){
                    margin-bottom:12vw;
                }
            }
            > div{
                width:67.19%;
                @include respond(desktop){
                    width:88%;
                }
            }
            li{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -moz-box-pack: start;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                -moz-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                @include respond(tablet){
                    -webkit-box-direction: normal;
                    -moz-box-direction: normal;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
                &:not(:first-child) em::before{
                    @include respond(tablet){
                        position:absolute;
                        top:-25px;
                        left:calc(50% - 5px);
                        content:'';
                        @include size-same(10px);
                        border:5px solid transparent;
                        border-top-color:$color-text-primary;
                    }
                }
                &:not(:last-child) em::after{
                    position:absolute;
                    bottom:-17px;
                    left:calc(50% - 5px);
                    content:'';
                    @include size-same(10px);
                    border:5px solid transparent;
                    border-top-color:$color-text-primary;
                    @include respond(tablet){
                        display:none;
                    }
                }
                em{
                    position:relative;
                    -webkit-box-flex: 0;
                    -moz-box-flex: 0;
                    -webkit-flex: 0 0 38.38%;
                    -ms-flex: 0 0 38.38%;
                    flex: 0 0 38.38%;
                    height:110px;
                    font-size:19px;
                    line-height:110px;
                    font-weight:bold;
                    text-align:center;
                    background:lighten($color-black, 97%);
                    @include respond(tablet){
                        -webkit-box-flex: 0;
                        -moz-box-flex: 0;
                        -webkit-flex: 0 1 auto;
                        -ms-flex: 0 1 auto;
                        flex: 0 1 auto;
                        width:90%;
                        max-width:450px;
                        letter-spacing:-1px;
                    }
                    @include rwd-down(576){
                        height:80px;
                        line-height:82px;
                        font-size:17px;
                    }
                }
                p{
                    padding-left:60px;
                    line-height:1.35;
                    color:$color-text-regular;
                    word-break:keep-all;
                    @include respond(desktop){
                        padding-left:5vw;
                    }
                    @include respond(tablet){
                        padding:20px 0 30px;
                    }
                    @include rwd-down(576){
                        padding:15px 5% 25px;
                        font-size:15px;
                        text-align:center;
                    }
                    strong{
                        color:$color-primary;
                    }
                    span{
                        display:block;
                        margin-top:9px;
                        font-size:15px;
                        @include rwd-down(576){
                            margin-top:5px;
                            font-size:13px;
                        }
                    }
                }
                & + li{
                    margin-top:20px;
                }
            }
        }
    }
}



//굿핀소개
.about{
    &-section{
        padding-bottom:130px;
        text-align:center;
        word-break:keep-all;
        @include rwd-down(576){
            padding-bottom:90px;
        }
        &:last-child{
            @include rwd-up(577){
                padding-bottom:150px;
            }
        }
        &-pink{
            background:$color-lighter-pink;
        }
        &.about-section-gray{
            background:$color-bg-lightgray;
            .card .txtbox{
                background:$color-white;
            }
        }
        &#present{
            .section-title{
                @include rwd-up(577){
                    padding-bottom:48px;
                }
            }
            ul{
                display: -webkit-box;
                display: -moz-box;
                display: -ms-inline-flexbox;
                display: -webkit-inline-flex;
                display: inline-flex;
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-box-pack: center;
                -moz-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -moz-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                @include rwd-down(576){
                    -webkit-box-direction: normal;
                    -moz-box-direction: normal;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }
            li{
                position:relative;
                @include size-same(300px);
                margin:0 50px;
                @include rounded(100%);
                background:$color-white;
                text-align:center;
                @include rwd-down(576){
                    @include size-same(72vw);
                }
                &:not(:last-child){
                    @include respond(desktop){
                        margin:0 50px 30px;
                    }
                    @include rwd-down(576){
                        margin:0 0 30px;
                    }
                }
                &::before{
                    position:absolute;
                    content:'';
                    display:block;
                    width:100%;
                }
                &:nth-child(1)::before{
                    top:35.5%;
                    height:55px;
                    background:url(../../assets/images/page/ico_giftcard.svg)no-repeat center;
                    background-size:auto 100%;
                    @include rwd-down(576){
                        top:22.5vw;
                        height:15vw;
                    }
                }
                &:nth-child(2)::before{
                    top:35.5%;
                    height:55px;
                    background:url(../../assets/images/page/ico_giftcard_used.svg)no-repeat center;
                    background-size:auto 100%;
                    @include rwd-down(576){
                        top:22.5vw;
                        height:15vw;
                        background-position:left 20.5vw center;
                    }
                }
                &:nth-child(3){
                    color:$color-primary;
                    &::before{
                        top:33%;
                        height:66px;
                        background:url(../../assets/images/page/ico_giftcard_sent.svg)no-repeat center;
                        background-size:auto 100%;
                        @include rwd-down(576){
                            top:19.7vw;
                            height:17.85vw;
                        }
                    }
                }
                strong{
                    display:block;
                    padding-top:203px;
                    font-size:19px;
                    font-weight:bold;
                    @include rwd-down(576){
                        padding-top:49vw;
                        font-size:17px;
                    }
                }
            }
        }
        .card{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-direction: normal;
            -moz-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            max-width:1280px;
            margin:0 auto;
            @include respond(desktop-lg){
                -webkit-box-direction: normal;
                -moz-box-direction: normal;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-flex-wrap: nowrap;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                width:80%;
            }
            @include rwd-down(576){
                width:calc(100% - 40px);
            }
            &:nth-child(even){
                .imgbox{
                    @include rwd-up(1280){
                        -webkit-box-ordinal-group: 3;
                        -moz-box-ordinal-group: 3;
                        -webkit-order: 2;
                        -ms-flex-order: 2;
                        order: 2;
                    }
                }
                .txtbox{
                    @include rwd-up(1280){
                        -webkit-box-ordinal-group: 2;
                        -moz-box-ordinal-group: 2;
                        -webkit-order: 1;
                        -ms-flex-order: 1;
                        order: 1;
                    }
                }
            }
            & + .card{
                margin-top:30px;
                @include rwd-down(576){
                    margin-top:40px;
                }
            }
            .imgbox{
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 50%;
                -ms-flex: 0 1 50%;
                flex: 0 1 50%;
                img{
                    @include respond(desktop-lg){
                        width:100%;
                    }
                }
            }
            .txtbox{
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 50%;
                -ms-flex: 0 1 50%;
                flex: 0 1 50%;
                padding:101px 90px 0 100px;
                background:lighten($color-black, 97%);
                letter-spacing:-0.8px;
                text-align:left;
                @include respond(desktop-lg){
                    padding:10% 10% 9.5%;
                }
                @include rwd-down(576){
                    padding:40px 30px 33px;
                }
                .tit{
                    font-size:29px;
                    line-height:1.4;
                    font-weight:bold;
                    @include rwd-down(576){
                        font-size:17px;
                    }
                }
                p{
                    margin-top:24px;
                    font-size:17px;
                    font-weight:300;
                    line-height:1.75;
                    color:$color-text-regular;
                    @include rwd-down(576){
                        margin-top:15px;
                        font-size:15px;
                    }
                    span{
                        @include rwd-up(768){
                            display:block;
                        }
                    }
                }
                .link{
                    margin-top:35px;
                    @include rwd-down(576){
                        margin-top:15px;
                    }
                    .btn-go{
                        padding:0;
                        border:0;
                        font-weight:500;
                        color:$color-primary;
                        @include rwd-down(576){
                            font-size:13px;
                        }
                        @include hover-active{
                            text-decoration:underline;
                            i{
                                @include animation('arrowMove 0.2s alternate infinite ease-in-out');
                                @include keyframes('arrowMove'){
                                    0%{
                                        margin-left:13px;
                                    }
                                    100%{
                                        margin-left:8px;
                                    }
                                }
                            }
                        }
                        i{
                            @include rwd-down(576){
                                position:relative;
                                bottom:-1px;
                            }
                        }
                    }
                }
            }
            //card about news
            &.news{
                .txtbox{
                    padding:60px 70px 0 80px;
                    @include respond(desktop-lg){
                        padding:9% 10% 10%;
                    }
                    @include rwd-down(576){
                        padding:35px 30px 42px;
                    }
                    p{
                        margin:0 0 20px;
                        font-weight:500;
                        color:$color-text-primary;
                        &::before{
                            display:block;
                            content:'';
                            width:20px;
                            height:18px;
                            margin-bottom:27px;
                            background:url(../../assets/images/page/ico_comma.png)center no-repeat;
                            background-size:100% auto;
                            @include rwd-down(576){
                                width:13px;
                                height:12px;
                                margin-bottom:12px;
                            }
                        }
                    }
                    small{
                        font-size:15px;
                        font-weight:300; 
                        line-height:1.35;
                        color:$color-text-regular;
                        @include rwd-down(576){
                            font-size:13px;
                        }
                    }
                }
            }
        }
        .section-title{
            padding-top:90px;
            padding-bottom:68px;
            text-align:center;
            @include rwd-down(576){
                padding-top:70px;
                padding-bottom:55px;
            }
            span{
                display:inline-block;
                margin-top:25px;
                font-size:15px;
                font-weight:500;
                color:$color-primary;
                @include rwd-down(576){
                    font-size:13px;
                }
            }
            h3{
                margin-top:25px;
                font-size:36px;
                letter-spacing:-1px;
                font-weight:bold;
                @include rwd-down(576){
                    margin-top:20px;
                    font-size:6vw;
                }
            }
            p{
                padding-top:37px;
                font-weight:300;
                line-height:1.75;
                color:$color-text-regular;
                @include respond(desktop){
                    padding-right:4vw;
                    padding-left:4vw;
                }
                @include rwd-down(576){
                    padding-top:25px;
                    padding-right:10vw;
                    padding-left:10vw;
                    font-size:15px;
                }
            }
        }
    }
    @at-root{
        .container#about{
            .contents > .inner{
                max-width:100%;
                @include respond(desktop-lg){
                    padding:0;
                }
            }
            & ~ footer{
                margin-top:auto;
                .customer{
                    border-bottom:1px solid $color-border-gray;
                    background:$color-white;
                }
            }
        }
    }
}



//약관
.terms{
    &-wrap{
        max-width:1100px;
        margin:0 auto;
        strong{
            font-size:19px;
            @include rwd-down(576){
                font-size:15px;
            }
            & + div{
                margin-top:15px;
                margin-bottom:30px;
                font-weight:300;
                color:$color-text-regular;
                line-height:1.75;
                @include rwd-down(576){
                    font-size:13px;
                }
                ol > li{
                    padding-left:10px;
                }
            }
        }
    }
}