.btn
  &.btn-secondary
    background-color: #e8eaee
    color: #606978
    border: 1px solid #e8eaee

  &.btn-outline-secondary
    border: 1px solid #eee
    color: #333

  &.btn-lg
    font-size: 14px
    height: 50px
    @media(max-width: 576px)
      line-height: inherit

.badge
  border-radius: 3px

input.form-control
  border: 1px solid #eee
  height: 50px
  padding: 0 1rem
  font-size: 14px
  border-radius: 5px

.bg-danger
  background-color: #EE4444 !important

.text-danger
  color: #EE4444 !important

.alert.alert-danger
  background-color: rgba(238, 68, 68, 0.05)
  border-radius: 8px

.text-gray
  color: #888 !important

.text-dark
  color: #333 !important

.bg-gray
  background-color: #f8f8f8

.bg-pink
  background-color: #FEF0F0 !important

.border-top
  border-top: 1px solid #eee

.border-bottom
  border-bottom: 1px solid #eee

.form-input-group
  position: relative
  border-bottom: 1px solid #E8EAEE
  padding: 0.5rem 0
  z-index: 1

  & > a.all-cursor
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    z-index: 1
    display: block
    cursor: text

  & > input
    display: block
    border: 0
    outline: 0
    margin-left: 2rem
    width: 100%

  & > a.icon
    position: absolute
    left: 0
    top: 50%
    transform: translate(0, -50%)

.background-guide
  position: absolute
  color: #fff
  left: 0
  top: 0
  bottom: 0
  right: 0
  z-index: 1

.middle-text
  position: absolute
  right: 0.25rem
  top: 50%
  transform: translate(0, -50%)
  z-index: 100
  &.left
    left: 0.25rem

.rounded-xl
  border-radius: 20px