
// color
$color-primary:#EE4444;
$color-light-pink:#FFF2F2;
$color-lighter-pink:#FFFafa;
$color-white:#fff;
$color-black:#000;
$color-text-primary:#222;
$color-text-regular:#888;
$color-text-secondary:#BDBDBD;
$color-border-gray:#eee;
$color-bg-gray:#F5F5F6;
$color-bg-lightgray:#F8F9FA;

// font
$font-stat:'Roboto', 'Noto Sans KR', Malgun Gothic, "맑은 고딕", AppleGothic, '돋움', Dotum, sans-serif;