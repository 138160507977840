.boot-alert
  position: fixed
  background-color: rgba(0, 0, 0, 0.7)
  left: 0
  top: 0
  bottom: 0
  right: 0
  display: block
  text-align: center
  padding: 1em
  white-space: nowrap
  z-index: 200000

  &:before
    display: inline-block
    content: ' '
    height: 100%
    vertical-align: middle

  .alert-inner
    max-width: 400px
    width: 100%
    display: inline-block
    vertical-align: middle
    border-radius: 5px
    overflow: hidden

    &:focus
      outline: none

    .header
      padding: 2rem 2rem 0 2rem
      background-color: #fff
      word-break: break-all
      white-space: pre-line
      text-align: left
      border-top-left-radius: 2px
      border-top-right-radius: 2px
      border-bottom: 1px solid #F6F9FC

      h3.alert-title
        color: #37394e
        font-weight: 600
        font-size: 18px
        padding: 0
        margin: 0

    & > .body
      padding: 3rem 2rem
      font-size: 14px
      margin-top: -1px
      background-color: #fff
      word-break: break-all
      white-space: pre-line
      text-align: center
      color: #252D3A
      font-weight: 400
      box-shadow: none
      outline: none

    & > #boot-alert-footer:focus
      background-color: red

    & > .footer
      width: 100%
      outline: none
      box-shadow: none
      background-color: #fff
      border-bottom-left-radius: 3px
      border-bottom-right-radius: 3px
      border-top: 1px solid #fff
      overflow: hidden
      text-align: right

      a:hover, a:focus
        text-decoration: none
        text-underline: none

      a.alert-button
        display: inline-block
        text-align: center
        padding: 1rem 0
        width: 50%
        font-size: 14px
        border: 0
        font-weight: 600
        border-top: 1px solid #E8EAEE

      a.cancel-btn
        display: inline-block
        text-align: center
        color: #252D3A
        background-color: #fff
        border-right: 1px solid #E8EAEE
        border-top: 1px solid #E8EAEE
        width: 50%

        &:hover
          color: #333
          background-color: #fff

      a.success-btn
        background-color: #fff
        color: #EE4444 !important

        &:hover
          background-color: #EE4444
          color: #fff !important
          border-top: 1px solid #EE4444