@charset "UTF-8";

.inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  @include respond(desktop-lg) {
    padding: 0 20px;
  }

  & > [class*="-wrap"]:not(.mypage-wrap) {
    background: $color-white;
  }
}

.container {
  min-height: calc(100vh - 842px);
  border-top: 1px solid $color-bg-gray;
}


header {
  h1 {
    position: relative;
    float: left;
    width: 109px;
    height: 80px;
    z-index: 7;
    @include respond(desktop) {
      height: 65px;
    }

    a {
      @include logo;
      @include size-same(100%);
      display: block;
      background-size: 109px auto;
    }
  }

  &.drawer-navbar {
    position: relative;
    height: 80px;
    border-bottom: none;
    background-color: $color-white;
    z-index: 10;
    @include respond(desktop) {
      height: 65px;
    }

    .drawer-nav {
      float: right;
      background-color: transparent;
      z-index: 6;
      @include respond(desktop) {
        width: 100%;
        background-color: $color-white;
      }

      > ul > li {
        &:last-child > a {
          padding: 11px 19px 12px;
          margin: 17px 0 0 30px;
          line-height: normal;
          font-size: 15px;
          font-weight: bold;
          color: $color-primary;
          border: 1px solid $color-primary;
          @include rounded(70px);
          @include rwd-up(1025) {
            @include hover-active {
              color: $color-white;
              background-color: $color-primary;
              @include transition(color .1s linear, background-color .1s linear);
            }
          }
          @include respond(desktop) {
            padding: 17px 27px;
            margin: 0;
            font-size: 21px;
            font-weight: normal;
            color: $color-text-primary;
            border: 0;
            @include hover-active {
              color: $color-primary;
            }
          }
        }

        &.open > a {
          color: $color-primary;
        }

        &.drawer-dropdown > a {
          @include respond(desktop) {
            @include arrow(9px, 9px, 2px, $color-text-primary, 135);
            &::after {
              margin: 0 0 5px 7px;
            }
          }
        }

        &.drawer-dropdown.open > a {
          @include respond(desktop) {
            @include arrow(9px, 9px, 2px, $color-text-primary, -45);
            &::after {
              margin: 5px 0 0 7px;
            }
          }
        }
      }

      .drawer--right & {
        right: -100%;
      }

      .drawer--right.drawer-open & {
        right: 0;
      }
    }

    .drawer-menu {
      @include respond(desktop) {
        padding: 33px 0;
      }
    }

    .drawer-menu-item {
      padding: 0px 28px;
      font-size: 17px;
      line-height: 80px;
      color: $color-text-primary;
      @include hover-active {
        @include transition(color .1s linear);
        color: $color-primary;
        text-decoration: none;
      }
      @include respond(desktop) {
        padding: 17px 27px;
        font-size: 21px;
        line-height: normal;
      }
    }

    .drawer-dropdown-menu {
      width: 140px;
      padding: 8px 0;
      margin: -5px 0 0 -12px;
      border: 0;
      @include rounded(12px);
      @include box-shadow(0 5px 20px 0 rgba($color-black, 13%));
      @include respond(desktop) {
        width: 100%;
        padding: 0 0 15px 0;
        margin: 0;
        @include box-shadow(0 5px 20px 0 rgba($color-black, 0%));
      }
    }

    .drawer-dropdown-menu-item {
      padding-top: 8px;
      padding-right: 10px;
      padding-bottom: 8px;
      padding-left: 10px;
      line-height: normal;
      font-size: 16px;
      letter-spacing: -1px;
      color: $color-text-regular;
      text-align: center;
      @include hover-active {
        @include transition(color .1s linear);
        color: $color-text-primary;
        text-decoration: none;
      }
      @include respond(desktop) {
        padding-top: 5px;
        padding-right: 30px;
        padding-bottom: 5px;
        padding-left: 30px;
        font-size: 17px;
        text-align: left;
      }
    }
  }

  .drawer-hamburger {
    position: absolute;
    width: 24px;
    padding: 21px 20px 30px;
    z-index: 7;

    span:first-child {
      @include sr-only;
    }
  }

  .drawer-hamburger-icon {
    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
    }
  }
}


.floating {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 2;
  @include respond(mobile) {
    right: 20px;
    bottom: 20px;
  }

  .updown {
    position: relative;
    display: none;
    @include size-same(60px);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background: $color-white;
    cursor: pointer;
    @include rounded(50%);
    @include box-shadow(0 5px 12px rgba($color-black, 0.09));
    @include hover-active {
      &.btn-top {
        &::after {
          top: 17px;
          @include respond(mobile) {
            top: 11px;
          }
        }

        em {
          padding-top: 33px;
          @include respond(mobile) {
            padding-top: 23px;
          }
        }
      }
      &.btn-bottom {
        &::after {
          bottom: 17px;
          @include respond(mobile) {
            bottom: 11px;
          }
        }

        em {
          padding-top: 18px;
          @include respond(mobile) {
            padding-top: 9px;
          }
        }
      }
      em {
        @include opacity(1);
      }
    }
    @include respond(mobile) {
      @include size-same(40px);
      font-size: 9px;
    }

    &.btn-top {
      @include arrow(12px, 12px, 2px, $color-text-primary, -45);
      @include respond(mobile) {
        @include arrow(8px, 8px, 2px, $color-text-primary, -45);
      }

      &::after {
        position: absolute;
        top: 26px;
        left: 24px;
        @include respond(mobile) {
          top: 18px;
          left: 16px;
        }
      }

      em {
        padding-top: 28px;
        @include respond(mobile) {
          padding-top: 20px;
        }
      }
    }

    &.btn-bottom {
      margin-top: 10px;
      @include arrow(12px, 12px, 2px, $color-text-primary, 135);
      @include respond(mobile) {
        @include arrow(8px, 8px, 2px, $color-text-primary, 135);
      }

      &::after {
        position: absolute;
        bottom: 26px;
        left: 24px;
        @include respond(mobile) {
          bottom: 18px;
          left: 16px;
        }
      }

      em {
        padding-top: 23px;
        @include respond(mobile) {
          padding-top: 12px
        }
      }
    }

    em {
      display: inline-block;
      width: 100%;
      @include opacity(0);
      @include transition(all .2s linear);
    }

    span {
      @include sr-only;
    }
  }
}


footer {
  //margin-top:220px;
  @include respond(tablet) {
    text-align: center;
  }

  .customer {
    padding: 65px 0 74px;
    background: $color-bg-gray;
    @include respond(tablet) {
      padding: 9.5vw 0 10vw;
    }

    strong {
      float: left;
      padding-top: 36px;
      font-size: 36px;
      font-weight: bold;
      color: $color-text-primary;
      @include respond(tablet) {
        float: none;
        padding-top: 0;
        font-size: 3vw;
      }
    }

    > div > div {
      text-align: right;
      line-height: normal;
      @include respond(tablet) {
        margin-top: 3vw;
        text-align: center;
      }

      a {
        font-size: 53px;
        font-weight: bold;
        color: $color-primary;
        @include respond(tablet) {
          font-size: 8vw;
        }

        span {
          display: inline-block;
          @include size-same(45px);
          margin-right: 6px;
          background: url(../../assets/images/page/ico_call.svg) no-repeat 0 5px;
          background-size: 100%;
          @include animation('iconCustomer 0.35s alternate infinite ease-in-out');
          @include keyframes('iconCustomer') {
            0% {
              @include rotate(-5);
            }
            100% {
              @include rotate(15);
            }
          }
          @include respond(tablet) {
            @include size-same(6.7vw);
            margin-right: 1vw;
            background-position: 0 0.7vw;
          }
        }
      }

      p {
        margin-top: 2px;
        font-size: 19px;
        //font-weight: 300;
        color: $color-text-regular;
        @include respond(tablet) {
          margin-top: 0.5vw;
          font-size: 3.3vw;
        }

        span {
          margin-right: 7px;
          color: $color-text-primary;
          font-weight: 500;

          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .company {
    padding: 55px 0 75px;
    @include respond(tablet) {
      padding: 8.5vw 0 10vw;
      font-size: 3vw;
    }

    .fmenu {
      display: inline-block;
      @include respond(tablet) {
        position: relative;
        top: 16vw;
      }

      a {
        display: inline-block;
        margin-right: 30px;
        //font-weight: 300;
        line-height: 55px;
        color: $color-text-regular;
        @include respond(tablet) {
          margin: 0 2vw;
          line-height: normal;
        }

        strong {
          font-weight: 500;
          color: $color-text-primary;
        }
      }
    }

    .family-site {
      position: relative;
      float: right;
      width: 266px;
      @include respond(tablet) {
        float: none;
        width: 100%;
        margin: -4.5vw 0 0;
      }

      button {
        width: 100%;
        padding: 18px 20px;
        font-size: 15px;
        font-weight: bold;
        color: $color-text-regular;
        text-align: left;
        background: $color-white;
        border: 1px solid $color-border-gray;
        @include arrow(8px, 8px, 2px, $color-text-regular, -45);
        @include respond(tablet) {
          padding: 3vw;
          font-size: 2.5vw;
          @include arrow(2vw, 2vw, 0.4vw, $color-text-regular, -45);
        }

        &::after {
          position: absolute;
          top: 26px;
          right: 22px;
          @include respond(tablet) {
            top: 4vw;
            right: 3.5vw;
          }
        }

        &[aria-expanded="true"]::after {
          top: 22px;
          @include rotate(135);
          @include respond(tablet) {
            top: 3vw;
          }
        }
      }

      .site-lst {
        position: absolute;
        left: 0;
        bottom: 54px;
        width: 266px;
        border: 1px solid $color-border-gray;
        background: $color-white;
        visibility: hidden;
        z-index: 9;
        @include opacity(0);
        @include transition(opacity 0.15s linear);
        @include respond(tablet) {
          bottom: 9vw;
          width: 100%;
        }

        &[aria-hidden="ture"] {
          @include opacity(0);
          visibility: hidden;
        }

        &[aria-hidden="false"] {
          @include opacity(1);
          visibility: visible;
        }

        a {
          display: block;
          padding: 15px 20px;
          color: $color-text-regular;

          &:hover {
            background: lighten($color-light-pink, 1.5%);
          }

          @include respond(tablet) {
            padding: 3vw;
          }
        }
      }
    }

    p {
      width: 70%;
      margin-top: 15px;
      line-height: 29px;
      color: $color-text-regular;
      @include respond(tablet) {
        width: 90%;
        margin: 16vw auto 0;
        line-height: 5vw;
      }

      span {
        display: inline-block;
        margin-right: 20px;
        @include respond(tablet) {
          margin: 0 1.5vw;
        }
      }
    }

    .copyright {
      margin-top: 39px;
      font-size: 15px;
      //font-weight: 300;
      color: $color-text-secondary;
      @include respond(tablet) {
        margin-top: 7vw;
        font-size: 2.5vw;
      }
    }
  }
}


.sub-tit {
  &.about-tit {
    h2 {
      height: 340px;
      line-height: 1.5;
      word-break: keep-all;
      @include rwd-down(640) {
        height: 65vw;
      }
      @include rwd-down(576) {
        height: 77vw;
        padding-top: 19.1vw;
        padding-right: 5vw;
        padding-left: 5vw;
      }

      span {
        display: block;
        font-weight: 100;
        @include rwd-down(640) {
          padding-right: 15vw;
          padding-left: 15vw;
        }
        @include rwd-down(576) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  .inner {
    position: relative;
  }

  h2 {
    height: 250px;
    padding-top: 97px;
    font-size: 42px;
    line-height: 1.5;
    font-weight: bold;
    color: $color-text-primary;
    text-align: center;
    @include rwd-down(576) {
      height: 47vw;
      padding-top: 21vw;
      font-size: 8vw;
    }
  }

  .circle-left-red {
    position: absolute;
    top: -116px;
    left: 60px;
    @include size-same(211px);
    background: url(../../assets/images/page/img_circle01.png) no-repeat;
    @include respond(tablet) {
      top: -15vw;
      left: 8vw;
      @include size-same(25vw);
      background-size: 100%;
    }
  }

  .circle-left-pink {
    position: absolute;
    top: 87px;
    left: 20px;
    @include size-same(35px);
    background: url(../../assets/images/page/img_circle02.png) no-repeat;
    @include respond(tablet) {
      top: 9vw;
      left: 5vw;
      @include size-same(4vw);
      background-size: 100%;
    }
  }

  .circle-right-pink {
    position: absolute;
    right: 134px;
    bottom: 32px;
    @include size-same(64px);
    background: url(../../assets/images/page/img_circle03.png) no-repeat;
    z-index: -1;
    @include respond(tablet) {
      right: 7vw;
      bottom: 2vw;
      @include size-same(8vw);
      background-size: 100%;
    }
    @include respond(mobile) {
      right: 8vw;
      bottom: 5vw;
    }
  }
}