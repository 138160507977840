$enable-shadows: true
$enable-transitions: true
$enable-prefers-reduced-motion-media-query: true
@import "~bootstrap/scss/mixins/box-shadow"
@import "~bootstrap/scss/mixins/transition"

@import './media'

#goodpin-main
  .main-index
    & > .slide-image
      padding: 2rem 0

    & > .gift-container
      & > .mx-1280
        & > h3.title
          padding-top: 6rem
          @include respond(tablet)
            padding-top: 4rem
          @include respond(mobile)
            padding-top: 2rem
          font-size: 53px
          line-height: 1.4
          font-weight: bold
          @media(max-width: 768px)
            font-size: 8vw

        & > .main-brand-list
          padding: 2rem 0

          & > .row
            & > .brand-container
              padding: 1rem

              a.brand
                position: relative
                display: block
                text-align: center
                border-radius: 50px
                box-shadow: 15px 20px 45px 5px rgba(0, 0, 0, 0.04)
                padding: 2rem
                background-color: var(--white)

                &:hover
                  @include box-shadow(0 0 10px 3px rgba(#EE4444, .07))
                  @include transition(box-shadow .15s linear)
                  top: -10px

                & > img
                  width: 100%

                &:after
                  display: inline-block
                  padding-top: 100%
                  content: ' '
                  vertical-align: middle

#goodpin-main.ok
  .main-index
    & > .gift-container
      background-color: #F4F4F6

  section#enjoy.main-section
    background-color: #F4F9FB
