@charset "UTF-8";

.arrow-right-move {
  @include animation('arrowMove 0.2s alternate infinite ease-in-out');
  @include keyframes('arrowMove') {
    0% {
      right: -5px;
    }
    100% {
      right: 0;
    }
  }
}

.arrow-left-move {
  @include animation('arrowMove 0.2s alternate infinite ease-in-out');
  @include keyframes('arrowMove') {
    0% {
      right: 5px;
    }
    100% {
      right: 0;
    }
  }
}

.main-section {
  @include clearfix;

  &:not(#visual,#voucher) {
    overflow: hidden;
  }

  .txtbox {
    @include respond(desktop) {
      margin-bottom: 12vw;
      text-align: center;
    }

    .tit {
      font-size: 53px;
      color: $color-text-primary;
      font-weight: bold;
      line-height: 66px;
      margin-bottom: 17px;
      @include respond(tablet) {
        font-size: 8vw;
        line-height: 10vw;
        margin-bottom: 3vw;
      }
    }

    p {
      font-size: 19px;
      color: $color-text-regular;
      line-height: 28px;
      margin-bottom: 64px;
      @include respond(tablet) {
        font-size: 3.3vw;
        line-height: 5vw;
        margin-bottom: 9vw;
        word-break: keep-all;
      }
      @include respond(mobile) {
        font-size: 3.7vw;
        line-height: 5.5vw;
      }
    }
  }

  .btnbox {
    a {
      position: relative;
      width: 190px;
      height: 55px;
      font-size: 15px;
      font-weight: 500;
      line-height: 55px;
      @include respond(tablet) {
        width: 30vw;
        height: 10vw;
        font-size: 2.5vw;
        line-height: 10vw;
      }

      &.btn-block {
        width: 100%;
        display: block;
        font-size: 14px;
      }

      span {
        @include sr-only;
      }

      &.btn-arrow {
        @include hover-active {
          text-decoration: underline;
          em::after {
            @include animation('arrowMove 0.2s alternate infinite ease-in-out');
            @include keyframes('arrowMove') {
              0% {
                right: -5px;
              }
              100% {
                right: 0;
              }
            }
          }
        }

        &.btn-always-hover {
          text-decoration: underline;

          em::after {
            @include animation('arrowMove 0.2s alternate infinite ease-in-out');
            @include keyframes('arrowMove') {
              0% {
                right: -5px;
              }
              100% {
                right: 0;
              }
            }
          }
        }

        em {
          padding-right: 16px;
          @include arrow(8px, 8px, 2px, $color-text-regular, 45);
          @include respond(tablet) {
            padding-right: 3.7vw;
            @include arrow(1.5vw, 1.5vw, 0.4vw, $color-text-regular, 45);
          }

          &::after {
            position: absolute;
            margin: 23px 0 1px 8px;
            @include respond(tablet) {
              margin: 4vw 0 0.1vw 2vw;
            }
          }
        }
      }
    }
  }
}


section#visual {
  padding: 60px 0 261px;
  @include respond(desktop) {
    padding: 12vw 0 13vw;
    overflow: hidden;
  }

  .txtbox {
    float: left;
    margin-top: 205px;
    @include respond(desktop-lg) {
      position: relative;
      z-index: 1;
    }
    @include respond(desktop) {
      float: none;
      margin-top: 0;
    }
  }

  .btnbox {
    a {
      @include rounded(3px);

      &.btn-google {
        width: 180px;
        background: url(../../assets/images/page/img_btn_googleplay.svg) no-repeat center;
        background-size: auto 19px;
        margin-right: 11px;
        @include respond(tablet) {
          width: 33vw;
          background-size: auto 3.8vw;
          margin-right: 1vw;
        }
      }

      &.btn-appstore {
        width: 180px;
        background: url(../../assets/images/page/img_btn_appstore.svg) no-repeat center top 16px;
        background-size: auto 19px;
        @include respond(tablet) {
          width: 29.5vw;
          background-size: auto 3.8vw;
          background-position: top 2.8vw center;
        }
      }
    }
  }


  .imgbox {
    position: absolute;
    right: 0;
    width: 55.3vw;
    height: 727px;
    overflow: hidden;
    @include respond(desktop-lg) {
      width: 60vw;
      margin-right: -3%;
      @include scale(0.9);
    }
    @include respond(desktop) {
      position: relative;
      width: 100vw;
      margin: 0 -20px;
      @include scale(1);
    }
    @include respond(tablet) {
      height: 82.5vw;
      margin: 0 -2rem;
    }

    &::before {
      @include respond(tablet) {
        position: relative;
        float: left;
        content: '';
        width: 8vw;
        height: 82.5vw;
        background: $color-white;
        z-index: 5;
      }
    }

    &::after {
      @include respond(tablet) {
        position: absolute;
        bottom: 0;
        left: 7.5vw;
        content: '';
        width: 85vw;
        height: 30vw;
        background: url(../../assets/images/page/bg_vis_mask.png) no-repeat right bottom;
        background-size: 100% auto;
        z-index: 3;
      }
    }

    .circle {
      * {
        position: absolute;
        z-index: 6;
      }

      .circle1 {
        top: 545px;
        left: 49px;
        @include size-same(20px);
        background: url(../../assets/images/page/img_vis_01.png) no-repeat;
        background-size: 100% auto;
        @include respond(desktop) {
          top: 60vw;
          left: 8vw;
          @include size-same(2.5vw);
        }
        @include respond(mobile) {
          top: 70vw;
          left: 10vw;
        }
      }

      .circle2 {
        top: 442px;
        left: 150px;
        @include size-same(42px);
        background: url(../../assets/images/page/img_vis_02.png) no-repeat;
        background-size: 100% auto;
        @include respond(desktop) {
          top: 51vw;
          left: 20vw;
          @include size-same(5vw);
        }
        @include respond(mobile) {
          top: 61vw;
          left: 22vw;
        }
      }

      .circle3 {
        top: 587px;
        left: 181px;
        @include size-same(10px);
        background: url(../../assets/images/page/img_vis_03.png) no-repeat;
        background-size: 100% auto;
        @include respond(desktop) {
          top: 65vw;
          left: 25vw;
          @include size-same(1.5vw);
        }
        @include respond(mobile) {
          top: 75vw;
          left: 27vw;
        }
      }

      .circle4 {
        top: 148px;
        left: 608px;
        @include size-same(55px);
        background: url(../../assets/images/page/img_vis_04.png) no-repeat;
        background-size: 100% auto;
        @include respond(desktop) {
          top: 12vw;
          left: 74vw;
          @include size-same(7vw);
        }
        @include respond(mobile) {
          top: 18vw;
          left: 80vw;
        }
      }

      .circle5 {
        top: 72px;
        left: 660px;
        @include size-same(16px);
        background: url(../../assets/images/page/img_vis_05.png) no-repeat;
        background-size: 100% auto;
        @include respond(desktop) {
          top: 4vw;
          left: 80vw;
          @include size-same(2vw);
        }
        @include respond(mobile) {
          top: 10vw;
          left: 86vw;
        }
      }
    }

    .mask {
      position: relative;
      width: 750px;
      height: 727px;
      background: $color-light-pink;
      @include respond(desktop) {
        margin: 0 auto;
      }
      @include respond(tablet) {
        width: 85vw;
        height: 82.5vw;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 375px;
        height: 727px;
        @include respond(tablet) {
          height: 100%;
        }
      }

      &::before {
        left: 0;
        background: url(../../assets/images/page/bg_vis_mask.png) no-repeat left;
        background-size: auto 100%;
        z-index: 5;
        @include respond(desktop) {
          z-index: 4;
        }
        @include respond(tablet) {
          width: 48vw;
          z-index: 5;
        }
      }

      &::after {
        right: 0;
        background: url(../../assets/images/page/bg_vis_mask.png) no-repeat right;
        background-size: auto 100%;
        @include respond(tablet) {
          width: 37vw;
        }
      }
    }

    .card-wrap {
      position: absolute;
      top: 225px;
      width: 4300px;
      z-index: 4;
      @include animation('cardSlide 50s infinite alternate ease-in-out');
      @include keyframes('cardSlide') {
        50% {
          @include transform(translateX(-80%));
        }
        100% {
          @include transform(translateX(7%));
        }
      }
      @include respond(desktop) {
        top: 24vw;
      }
      @include respond(tablet) {
        top: 29vw;
      }
      @include respond(mobile) {
        top: 35vw;
      }

      img {
        display: inline-block;
        margin-left: -54px;
        @include respond(tablet) {
          width: 40vw;
          margin-left: -7vw;
        }
        @include respond(mobile) {
          width: 55vw;
          margin-left: -10vw;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .device {
      position: absolute;
      top: 68px;
      left: 248px;
      z-index: 3;
      @include respond(desktop-lg) {
        left: 20vw;
        @include scale(1.1);
      }
      @include respond(desktop) {
        top: 9vw;
        left: 36vw;
      }
      @include respond(tablet) {
        left: 33vw;
        width: 55vw;
      }
      @include respond(mobile) {
        top: 7vw;
        left: 25vw;
        @include scale(1);
      }
    }
  }
}


section#enjoy {
  padding: 76px 0 170px;
  @include respond(desktop-lg) {
    position: relative;
  }
  @include respond(desktop) {
    padding: 12vw 0 710px;
  }
  @include respond(tablet) {
    padding: 12vw 0 98vw;
  }

  .imgbox {
    position: relative;
    display: inline-block;
    @include size-same(570px);
    margin-right: 117px;
    background: url(../../assets/images/page/bg_enj.png) no-repeat;
    background-size: 100% auto;
    @include respond(desktop-lg) {
      margin-right: 0;
    }
    @include respond(desktop) {
      position: absolute;
      top: 570px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    @include respond(tablet) {
      top: 76vw;
      @include size-same(83vw);
    }

    .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 601px;
      height: 130px;
      background: url(../../assets/images/page/bg_enj_mask.png) no-repeat bottom;
      background-size: 100% auto;
      z-index: 2;
      @include respond(tablet) {
        left: -1.9vw;
        width: 90vw;
        height: 20vw;
      }
    }

    .google {
      position: absolute;
      top: 5px;
      left: 67px;
      width: 120px;
      height: 137px;
      background: url(../../assets/images/page/img_enj_01.png) no-repeat;
      background-size: 100% auto;
      z-index: 4;
      @include respond(tablet) {
        top: 1vw;
        left: 9vw;
        width: 19vw;
        height: 21vw;
      }
    }

    .coffee {
      position: absolute;
      right: -13px;
      bottom: 13px;
      width: 202px;
      height: 252px;
      background: url(../../assets/images/page/img_enj_02.png) no-repeat;
      background-size: 100% auto;
      z-index: 1;
      @include respond(tablet) {
        right: -3vw;
        bottom: 2.7vw;
        width: 30vw;
        height: 36vw;
      }
    }

    .device {
      position: relative;
      margin: -23px 0 0 123px;
      z-index: 3;
      @include respond(tablet) {
        width: 47vw;
        margin: -3vw auto 0;
      }

      img {
        @include respond(tablet) {
          width: 100%;
        }
      }
    }
  }

  .txtbox {
    display: inline-block;
    margin-top: 113px;
    vertical-align: top;
    @include respond(desktop-lg) {
      position: absolute;
      top: 76px;
      right: 5vw;
    }
    @include respond(desktop) {
      position: relative;
      top: auto;
      right: auto;
      display: block;
      margin-top: 0;
    }
  }
}


section#security {
  padding: 90px 0 110px;
  @include respond(desktop-lg) {
    position: relative;
  }
  @include respond(desktop) {
    padding: 12vw 0 13vw;
  }

  .txtbox {
    display: inline-block;
    margin-top: 76px;
    @include respond(desktop) {
      display: block;
      margin-top: 0;
    }
  }

  .imgbox {
    position: relative;
    float: right;
    margin-right: 45px;
    width: 550px;
    height: 379px;
    background: url(../../assets/images/page/bg_sec.png) no-repeat;
    background-size: 100% auto;
    @include respond(desktop-lg) {
      position: absolute;
      top: 90px;
      right: 3vw;
      float: none;
      margin-right: 0;
    }
    @include respond(desktop) {
      position: relative;
      top: auto;
      right: auto;
      margin: -80px auto 0;
    }
    @include respond(tablet) {
      width: 80vw;
      height: 57vw;
      margin-top: -13vw;
    }

    .browser {
      width: 356px;
      height: 279px;
      margin: 96px 0 0 101px;
      background: url(../../assets/images/page/img_sec_01.png) no-repeat;
      background-size: 100% auto;
      z-index: 1;
      @include respond(desktop) {
        position: absolute;
      }
      @include respond(tablet) {
        width: 52vw;
        height: 40vw;
        margin: 13.7vw 0 0 14.5vw;
      }
    }

    .lock {
      position: absolute;
      top: 170px;
      left: 228px;
      width: 102px;
      height: 118px;
      background: url(../../assets/images/page/img_sec_02.png) no-repeat;
      background-size: 100% auto;
      z-index: 2;
      @include respond(tablet) {
        top: 24.7vw;
        left: 33.3vw;
        width: 14.4vw;
        height: 16vw;
      }
    }

    .num {
      position: absolute;
      top: 276px;
      left: 184px;
      width: 190px;
      height: 34px;
      background: url(../../assets/images/page/img_sec_03.png) no-repeat;
      background-size: 100% auto;
      z-index: 2;
      @include respond(tablet) {
        top: 40vw;
        left: 26.8vw;
        width: 27.3vw;
        height: 5vw;
      }
    }
  }
}


section#payment {
  padding: 118px 0 110px;
  @include respond(desktop-lg) {
    position: relative;
  }
  @include respond(desktop) {
    padding: 12vw 0 710px;
  }
  @include respond(tablet) {
    padding: 12vw 0 98vw;
  }

  .imgbox {
    position: relative;
    display: inline-block;
    width: 550px;
    margin-right: 167px;
    vertical-align: top;
    @include respond(desktop-lg) {
      margin-right: 0;
    }
    @include respond(desktop) {
      position: absolute;
      top: 500px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    @include respond(tablet) {
      top: 65vw;
      width: auto;
      text-align: center;
    }

    .circle-card {
      position: absolute;
      top: 18px;
      left: 14px;
      @include size-same(196px);
      background: url(../../assets/images/page/img_pay_01.png) no-repeat;
      background-size: 100% auto;
      z-index: 3;
      @include respond(tablet) {
        top: 2.5vw;
        left: 11vw;
        @include size-same(28.5vw);
      }
    }

    .circle-phone {
      position: absolute;
      top: 384px;
      right: 13px;
      @include size-same(176px);
      background: url(../../assets/images/page/img_pay_02.png) no-repeat;
      background-size: 100% auto;
      z-index: 3;
      @include respond(tablet) {
        top: 55.5vw;
        right: 12.3vw;
        @include size-same(26vw);
      }
    }

    .device {
      position: relative;
      padding-left: 120px;
      z-index: 2;
      @include respond(tablet) {
        width: 46.5vw;
        padding-left: 0;
        margin: 0 auto;
      }

      img {
        @include respond(tablet) {
          width: 100%;
        }
      }
    }

    .layer-gray {
      position: absolute;
      top: 110px;
      left: 186px;
      width: 260px;
      height: 480px;
      background: $color-bg-gray;
      z-index: 1;
      @include rounded(25px);
      @include rotate(10);
      @include respond(tablet) {
        top: 13.8vw;
        left: 36.3vw;
        width: 38.5vw;
        height: 72vw;
      }
    }
  }

  .txtbox {
    display: inline-block;
    margin-top: 156px;
    @include respond(desktop-lg) {
      position: absolute;
      top: 118px;
      right: 4vw;
    }
    @include respond(desktop) {
      position: relative;
      top: auto;
      right: auto;
      display: block;
      margin-top: 0;
    }

    .tag {
      strong {
        font-size: 19px;
        color: $color-primary;
        font-weight: 500;
        margin-right: 30px;
        @include respond(tablet) {
          font-size: 3.3vw;
          margin-right: 3vw;
        }
      }
    }
  }
}


section#voucher {
  padding: 144px 0 0;
  @include respond(desktop) {
    padding: 12vw 0 0;
  }

  .lstbox {
    margin: 58px -22px 0 -23px;
    @media only screen and (max-width: 1340px) {
      margin: 58px 0 0;
    }
    @include respond(desktop) {
      margin: -20px 0 0;
      text-align: center;
    }
    @include respond(mobile) {
      margin: 0;
    }

    a {
      position: relative;
      top: 0;
      float: left;
      @include size-same(220px);
      margin: 22px 22px 23px 23px;
      @include rounded(75px);
      @include box-shadow(15px 20px 45px 5px rgba($color-black, 4%));
      @include transition(all .1s linear);
      @include hover-active {
        top: -10px;
        @include box-shadow(15px 20px 45px 5px rgba($color-black, 6%));
        @include respond(tablet) {
          top: -1.5vw;
          @include box-shadow(1.5vw 2vw 3.5vw 0.8vw rgba($color-black, 6%));
        }
        &::before {
          @include box-shadow(inset -11px -10px 15px 7px rgba($color-black, 2%));
          @include respond(tablet) {
            @include box-shadow(inset -1.5vw -2vw 3.5vw 2.5vw rgba($color-black, 2%));
          }
        }
      }
      @include respond(desktop) {
        display: inline-block;
        float: none;
      }
      @include respond(tablet) {
        @include size-same(38vw);
        margin: 0 2vw 5vw;
        @include rounded(13vw);
        @include box-shadow(1.5vw 2vw 3.5vw 0.8vw rgba($color-black, 4%));
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include rounded(75px);
        @include box-shadow(inset -11px -10px 8px 5px rgba($color-black, 1%));
        @include respond(tablet) {
          @include rounded(13vw);
          @include box-shadow(inset -1.5vw -2vw 3vw 2vw rgba($color-black, 1%));
        }
      }

      .thumbnail {
        @include size-same(100%);
        @include rounded(75px);
        @include respond(tablet) {
          @include rounded(13vw);
        }
      }
    }
  }
}